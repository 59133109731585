import { Component, inject } from '@angular/core';
import { Location } from '@angular/common';
import { NavBarEvents } from '../../consts/nav-bar-events';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementConfig } from '../../models/element-config';
import { PopupComponent } from '../popup/popup.component';
import { PopupLabels } from '../../utils/editor-util';
import { VerifyTokenService } from '@app/core/verify-token/verify-token.service';
import { SignOutAlertComponent } from '@app/shared/sign-out/sign-out-alert/sign-out-alert.component';
import { TemplateService } from '@app/shared/services/template.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { CanvasElementConfig } from '../../models/template-config';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
})
export class NavBarComponent {
  readonly dialog = inject(MatDialog);

  unsavedChanges = false;
  comments = '';
  templateId: string | null = null;
  jobCode!: string;
  templateData: any;

  status = "In Progress";

  constructor(private canvasEditorService: CanvasEditorService, private router: Router, private templateService: TemplateService,
    private route: ActivatedRoute,
    private spinnerService:SpinnerService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async params => {
      this.templateId = params.get('templateId');
      this.jobCode = params.get('jobCode') || '';
      if (this.templateId) {
        this.fetchTemplateData(this.templateId);
      }
  });
  }

  fetchTemplateData(templateId: string) {
    this.spinnerService.show();
    this.templateService.getTemplateById(templateId).subscribe(
      response => {
        this.templateData = response;
        this.spinnerService.hide();
      }
    );
  }

  save() {
    this.canvasEditorService.updateSaveInteraction(NavBarEvents.Save);
    this.unsavedChanges = false;
  }

  preview() {
    this.canvasEditorService.updateSaveInteraction(NavBarEvents.Preview);
  }

  // submit() {
  //   this.canvasEditorService.updateSaveInteraction(NavBarEvents.Submit);
  // }

  submit() {
    // const masterTemplateConfig = this.getMasterTemplateConfig();
    // const allCustomizable = masterTemplateConfig.canvas_configuration.every(
    //   (canvasElement: CanvasElementConfig) =>
    //     canvasElement.customizable === true
    // );

    // if (!allCustomizable) {
    //   alert('Map all modules for the template before submitting.');
    //   return;
    // }

    const dialogRef = this.dialog.open(PopupComponent, {backdropClass:'custom-dialog',
        data: {
        type: 'confirmSubmit',
        resourceName: this.templateData.template_name,
        contentTemplate: this.getCommentsTemplate(),
        comments: this.canvasEditorService.getComments(),
        onYes: (comments: string) => {
          this.canvasEditorService.setComments(comments);
          this.handleSubmission();
        },
        onNo: () => this.handleNo(),
      },
      width: '600px',
    });
  }

  handleSubmission() {
    this.spinnerService.show();
    const elementConfig = this.getElementConfig();
    const masterTemplateConfig = this.getMasterTemplateConfig();
    const status = 'SUBMITTED';
    this.status = status;

    if (masterTemplateConfig.template_type.toLowerCase() === 'picklist') {
      this.canvasEditorService.doSave(
        masterTemplateConfig,
        'submit',
        this.canvasEditorService.getComments(),
        'SUBMITTED'
      );
    } else {
      this.canvasEditorService.updateSaveInteraction(NavBarEvents.Submit);
    }
    this.unsavedChanges = false;
    setTimeout(() => {
      this.spinnerService.hide();
      this.dialog.open(PopupComponent, {backdropClass:'custom-dialog',
        data: {
          type: 'submissionSuccess',
          onOk: () => this.router.navigate(['/dashboard/templates']),
        },
        width: '600px',
      });
    },1500);
  }

  getElementConfig(): ElementConfig[] {
    const elements = this.canvasEditorService.getElements();
    return elements;
  }

  getMasterTemplateConfig(): any {
    return this.canvasEditorService.getTemplateConfig;
  }

  getCommentsTemplate() {
    return `
      <mat-form-field>
        <mat-label>${PopupLabels.confirmSubmit.label}</mat-label>
        <textarea matInput placeholder="${PopupLabels.confirmSubmit.placeholder}" [(ngModel)]="comments"></textarea>
      </mat-form-field>
    `;
  }

  handleYes() {
    this.status = "Approval Pending";
    this.dialog.open(PopupComponent, {
      backdropClass:'custom-dialog',
      data: {
        type: 'submissionSuccess',
        onOk: () => this.router.navigate(['/dashboard']),
      },
      width: '600px',
    });
  }
  handleNo() {
    // Handle No action
  }
  onTestTemplate(): void {
    if (this.unsavedChanges) {
      if (confirm('You have unsaved changes. Please save your data before proceeding to the TEST.')) {
        this.save();
        setTimeout(() => {
          if (!this.canvasEditorService.hasCustomisableSection()) {
            alert('At least one customisable section must be available in the configuration.');
            return;
          }
          this.router.navigate(['/test-template']);
        }, 0);
      } else {
        return;
      }
    } else {
      // Check for at least one customisable section
      if (!this.canvasEditorService.hasCustomisableSection()) {
        alert('At least one customisable section must be available in the configuration.');
        // return;
      }
      this.router.navigate(['/personalized-resource', this.templateId,this.jobCode]);
    }
  }

  config() {
    let template = this.templateService.getStoredTemplateDetails();
    this.router.navigate(['/config-list', template]);
  }
  
  logout() {
    this.dialog.open(SignOutAlertComponent, {
      backdropClass:'custom-dialog',
      width: '350px'
    });
  }

}
