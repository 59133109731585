<div class="dialog-content">
  <div class="dialog-header">
    <span mat-dialog-title>{{ data.title }}</span>
    <!-- <button class="close-btn" (click)="onClose()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M5.69343 4.27727C5.30291 3.88674 4.66975 3.88674 4.27922 4.27727C3.8887 4.66779 3.8887 5.30096 4.27922 5.69148L10.5858 11.9981L4.27922 18.3047C3.8887 18.6952 3.8887 19.3284 4.27922 19.7189C4.66975 20.1094 5.30291 20.1094 5.69343 19.7189L12 13.4123L18.3066 19.7189C18.6971 20.1094 19.3303 20.1094 19.7208 19.7189C20.1114 19.3284 20.1114 18.6952 19.7208 18.3047L13.4142 11.9981L19.7208 5.69148C20.1114 5.30096 20.1114 4.66779 19.7208 4.27727C19.3303 3.88674 18.6971 3.88674 18.3066 4.27727L12 10.5839L5.69343 4.27727Z" fill="#333333"/>
      </svg>
    </button> -->
  </div>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions>
      <button class="ok" (click)="onClose()">OK</button>
    </div>
  </div>
  