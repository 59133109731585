import { endpoints, idp } from '../app/shared/constants/auth.constants';

const { 
  tbtDomainUrl, heqPortaledirectionUrl,
  cognitoClientId, cognitoClientSecret, cognitoDomainUrl, cognitoIdentityProvider, cognitoRedirectUrl, 
  proxyApi, proxyApiKey,
  apiGatewayResourceId, apiGatewayVPCEndpointId, awsRegion
} = idp.uat;

const scope = idp.scope;

const oauth2AuthorizePath = '/oauth2/authorize';
const responseType = 'CODE';

const pingfedAuthUrl = `${cognitoDomainUrl}${oauth2AuthorizePath}?identity_provider=${cognitoIdentityProvider}&redirect_uri=${cognitoRedirectUrl}&response_type=${responseType}&client_id=${cognitoClientId}&scope=${scope}`;

export const environment = {
  production: false,
  cognito_domain: cognitoDomainUrl,
  cognitoUrl: `${cognitoDomainUrl}/oauth2/token`,
  clientId: cognitoClientId,
  clientSecret: cognitoClientSecret,
  redirectUri: `${tbtDomainUrl}/verify-token`,
  redirectUriLogout: `${tbtDomainUrl}/sign-out`,
  pingfedAuthUrl,
  designsEndPoint: `/services/apexrest/${endpoints.designs}`,
  templatesEndPoint: `/services/apexrest/${endpoints.templates}`,
  apiGatewayUrl: `https://${apiGatewayResourceId}-${apiGatewayVPCEndpointId}.execute-api.${awsRegion}.amazonaws.com/v1/`,
  apiProxyApi: proxyApi,
  apiProxyKey: proxyApiKey,
  portalRedirectLink: heqPortaledirectionUrl,
 };