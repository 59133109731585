<div class="left-nav">
  <div class="scrollable-section">
    <div class="file-list">
      @for (image of availableImages; track image.id) {
        <div class="file-item" [matTooltip]="image.name" [matTooltipPosition]="'after'">
          <img [src]="image.designUrl" alt="Static File" draggable="true" (dragstart)="onDragStart($event, image)" style="max-width: 100px; max-height: 150px;"/>
          <div class="image-name">{{image.name}}</div>
        </div>
      }
    </div>
  </div>
</div>