import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-sign-out',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './sign-out.component.html'
  })
  export class SignOutComponent implements OnInit {
    ngOnInit(): void {
        
    }

  }