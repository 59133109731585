<div class="top-nav">
  <!-- <h3>Template Builder</h3> -->
  <div class="button-group">
    <button class="save-button" (click)="save()">Save</button>
    <button class="preview-button" (click)="preview()">Preview</button>
    <button class="config-button" (click)="config()">Configure Module</button>
    <button class="test-button" (click)="onTestTemplate()">Test</button>
    <button class="submit-button" (click)="submit()">Submit</button>
    <div class="separator"></div>
  </div>
</div>