import { createReducer, on } from '@ngrx/store';
import { loadSourceTree, loadSourceTreeSuccess } from '../../actions/standardise-design.actions';
import { FolderStructure } from '../../models/folder-structure.model';
import { buildTreeFromS3 } from '@app/shared/helpers/tree-helpers';

export const initialState: FolderStructure = {
  treeData: [
    {
      name: 'Design',
      children: [
        { name: 'Thumbnail File' },
        { name: 'InDesign File' },
        { name: 'Mapping Guidelines file' },
        { name: 'Final Resource' },
        {
          name: 'Static Files',
          children: [],
        },
        {
          name: 'Module Assets',
          children: [],
          //showAddButton: true,
        },
      ],
    }
  ],
  loading: false,
  job: ""
};

export const sourceTreeReducer = createReducer(
  initialState,
  on(loadSourceTree, (state, {folder}) => {
    return {...state, loading: true, job: folder}
  }),
  on(loadSourceTreeSuccess, (state, { payload }) => {
    return {
      ...state,
      treeData: (payload.body.contents?.length) ? buildTreeFromS3(payload.body.contents) : [],
      loading: false,
      data: payload
    };
  }),
);

