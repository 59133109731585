<h2 mat-dialog-title class="dialog-title">
  <span>{{ data.title }}</span>
  <button
    mat-icon-button
    (click)="oncancelClick()"
    aria-label="Close"
    class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <ng-container class="OnCustomizableImageClick">
    <div class="box-layout">
      <div class="image-gallery">
        @for (image of matchingImages; track image; let i = $index) {
          <div
            class="image-item"
            [class.selected]="selectedImageIndex === i"
            (click)="onImageClick(i)">
            @if (selectedImageIndex === i) {
              <div class="tick-box">
                <svg
                  class="tick-icon"
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.26155 11.6511L15.5074 2.47942C15.7594 2.2294 15.7611 1.82233 15.511 1.57022L14.3038 0.3532C14.0537 0.101143 13.6467 0.0995208 13.3947 0.349538L5.82221 7.86115L2.61906 4.63201C2.36905 4.3799 1.96203 4.37828 1.70997 4.62829L0.492895 5.83559C0.240785 6.08566 0.239163 6.49273 0.48918 6.74478L5.35246 11.6474C5.60248 11.8995 6.0095 11.9011 6.26155 11.6511Z"
                    fill="white" />
                  </svg>
                </div>
              }
              <img [src]="image" alt="Image {{ i + 1 }}" class="image-preview" />
              <span class="image-label"
                >{{ i + 1 }}. {{ data.templateName }}</span
                >
              </div>
            }
          </div>
        </div>
      </ng-container>
    </mat-dialog-content>

    <div class="dialog-footer">
      <button class="footer-button cancel-button" (click)="oncancelClick()">
        Cancel
      </button>
      <button mat-dialog-close="true"
        class="footer-button select-button"
        [disabled]="isSelectButtonDisabled"
        (click)="onselectClick()">
        Select
      </button>
    </div>
