import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private _jobCode?: string;
  private _userId?: string;
  private _templateId?: string;
  private _designName?: string;

  get jobCode(): string | undefined {
    return this._jobCode;
  }

  set jobCode(value: string | undefined) {
    this._jobCode = value;
  }

  get userId(): string | undefined{
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  get templateId(): string | undefined {
    return this._templateId;
  }

  set templateId(value: string | undefined) {
    this._templateId = value;
  }

  get designName(): string | undefined {
    return this._designName;
  }

  set designName(value: string | undefined) {
    this._designName = value;
  }
}
