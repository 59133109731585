<div class="mat-tree-node node-content" [draggable]="node.draggable" (dragleave)="onDragEnd($event)" (dragend)="onDragEnd($event)" (dragstart)="onDragStart($event, node)" (dragover)="onDragOver($event, node)" (drop)="onDrop($event, node)">
  @if (hasChild) {
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon>{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon>
    </button>
  }
  {{ node.name }}
  @if (dropPlaceholder) {
    <div class="drop-placeholder"><div class="badge bg-secondary">Drop here</div></div>
  }

  @if (node.upload) {
    <span>: {{ node.uploadedFileName }}</span>
  }

  @if (node.upload) {
    <div>
      <button mat-icon-button (click)="fileInput.click()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9.375 7.44772H11.107V15.3766C11.107 15.4798 11.1914 15.5641 11.2945 15.5641H12.7008C12.8039 15.5641 12.8883 15.4798 12.8883 15.3766V7.44772H14.625C14.782 7.44772 14.8687 7.26725 14.7727 7.14538L12.1477 3.82194C12.1301 3.79953 12.1077 3.78141 12.0821 3.76894C12.0565 3.75648 12.0285 3.75 12 3.75C11.9715 3.75 11.9435 3.75648 11.9179 3.76894C11.8923 3.78141 11.8699 3.79953 11.8523 3.82194L9.22734 7.14304C9.13125 7.26725 9.21797 7.44772 9.375 7.44772ZM20.5781 14.6735H19.1719C19.0688 14.6735 18.9844 14.7579 18.9844 14.861V18.4704H5.01562V14.861C5.01562 14.7579 4.93125 14.6735 4.82812 14.6735H3.42188C3.31875 14.6735 3.23438 14.7579 3.23438 14.861V19.5016C3.23438 19.9165 3.56953 20.2516 3.98438 20.2516H20.0156C20.4305 20.2516 20.7656 19.9165 20.7656 19.5016V14.861C20.7656 14.7579 20.6812 14.6735 20.5781 14.6735Z" fill="black" fill-opacity="0.45"/>
        </svg>
      </button>
      <input type="file" #fileInput (change)="onFileSelected($event, node)" style="display: none;" />
    </div>
  }


  @if (node.addFolder) {
    <button mat-icon-button (click)="onAddFolderClick(node)">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z" fill="#8C8C8C"/>
        <path d="M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z" fill="#8C8C8C"/>
      </svg>
    </button>
  }

  @if (node.properties) {
    <button mat-icon-button (click)="editProperties(node)">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6.03984 17.625C6.08672 17.625 6.13359 17.6203 6.18047 17.6133L10.1227 16.9219C10.1695 16.9125 10.2141 16.8914 10.2469 16.8563L20.182 6.92109C20.2038 6.89941 20.221 6.87366 20.2328 6.8453C20.2445 6.81695 20.2506 6.78656 20.2506 6.75586C20.2506 6.72516 20.2445 6.69477 20.2328 6.66642C20.221 6.63806 20.2038 6.61231 20.182 6.59063L16.2867 2.69297C16.2422 2.64844 16.1836 2.625 16.1203 2.625C16.057 2.625 15.9984 2.64844 15.9539 2.69297L6.01875 12.6281C5.98359 12.6633 5.9625 12.7055 5.95312 12.7523L5.26172 16.6945C5.23892 16.8201 5.24707 16.9493 5.28545 17.071C5.32384 17.1927 5.39132 17.3032 5.48203 17.393C5.63672 17.543 5.83125 17.625 6.03984 17.625ZM7.61953 13.5375L16.1203 5.03906L17.8383 6.75703L9.3375 15.2555L7.25391 15.6234L7.61953 13.5375ZM20.625 19.5938H3.375C2.96016 19.5938 2.625 19.9289 2.625 20.3438V21.1875C2.625 21.2906 2.70937 21.375 2.8125 21.375H21.1875C21.2906 21.375 21.375 21.2906 21.375 21.1875V20.3438C21.375 19.9289 21.0398 19.5938 20.625 19.5938Z" fill="#8C8C8C"/>
      </svg>
    </button>
  }

  @if (!node.children) {
    <button mat-icon-button (click)="removeNode(node)">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M3.7953 2.85151C3.53495 2.59116 3.11284 2.59116 2.85249 2.85151C2.59214 3.11186 2.59214 3.53397 2.85249 3.79432L7.05688 7.99872L2.85249 12.2031C2.59214 12.4635 2.59214 12.8856 2.85249 13.1459C3.11284 13.4063 3.53495 13.4063 3.7953 13.1459L7.99969 8.94152L12.2041 13.1459C12.4644 13.4063 12.8865 13.4063 13.1469 13.1459C13.4072 12.8856 13.4072 12.4635 13.1469 12.2031L8.9425 7.99872L13.1469 3.79432C13.4072 3.53397 13.4072 3.11186 13.1469 2.85151C12.8865 2.59116 12.4644 2.59116 12.2041 2.85151L7.99969 7.05591L3.7953 2.85151Z" fill="#8C8C8C"/>
      </svg>
    </button>
  }

  @if (showAddFolderForm) {
    <div class="add-folder-form">
      <input mat-form-field #folderNameInput type="text" placeholder="Enter folder name" />
      <button mat-button (click)="onSubmitNewFolder(folderNameInput.value)">Submit</button>
    </div>
  }
</div>