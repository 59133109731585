import { Component, Inject,Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-preview',
  standalone: true,
  imports: [],
  templateUrl: './image-preview.component.html',
  styleUrl: './image-preview.component.scss'
})
export class ImagePreviewComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { imageUrl: string, title: string  },
    private dialogRef: MatDialogRef<ImagePreviewComponent>
  ) {}

  close() {
    this.dialogRef.close();
  }
}