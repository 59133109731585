<div class="main-section">
  <div class="header-section">
  <span class="header-text">
    Design Folder Structure
    <span class="close-icon" (click)="closeDialog()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M5.69343 4.51946C5.30291 4.12893 4.66975 4.12893 4.27922 4.51946C3.8887 4.90998 3.8887 5.54314 4.27922 5.93367L10.5858 12.2403L4.27922 18.5469C3.8887 18.9374 3.8887 19.5705 4.27922 19.9611C4.66975 20.3516 5.30291 20.3516 5.69343 19.9611L12 13.6545L18.3066 19.9611C18.6971 20.3516 19.3303 20.3516 19.7208 19.9611C20.1114 19.5705 20.1114 18.9374 19.7208 18.5469L13.4142 12.2403L19.7208 5.93367C20.1114 5.54314 20.1114 4.90998 19.7208 4.51946C19.3303 4.12893 18.6971 4.12893 18.3066 4.51946L12 10.826L5.69343 4.51946Z" fill="#333333"/>
      </svg>
    </span>
  </span>
  </div>
  
    <svg xmlns="http://www.w3.org/2000/svg" width="767" height="2" viewBox="0 0 767 2" fill="none">
      <path d="M0 1L767 1.00007" stroke="#D9D9D9"/>
      </svg>
  
  <mat-dialog-content class="dialog-content">
    <app-spinner></app-spinner>
  
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
      <!-- Default node definition for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <div>
          {{ node.name }}
          @if (node.view) {
            <a (click)="openImagePreview(node.link, node.name)">
              <i class="bi bi-eye"></i>
            </a>
          }
          @if (node.download) {
            <a (click)="downloadFile(node.filePath)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M11.8523 15.4922C11.8699 15.5146 11.8923 15.5327 11.9179 15.5452C11.9435 15.5577 11.9715 15.5641 12 15.5641C12.0285 15.5641 12.0565 15.5577 12.0821 15.5452C12.1077 15.5327 12.1301 15.5146 12.1477 15.4922L14.7727 12.1711C14.8687 12.0492 14.782 11.8687 14.625 11.8687H12.8883V3.9375C12.8883 3.83437 12.8039 3.75 12.7008 3.75H11.2945C11.1914 3.75 11.107 3.83437 11.107 3.9375V11.8664H9.375C9.21797 11.8664 9.13125 12.0469 9.22734 12.1688L11.8523 15.4922ZM20.5781 14.6719H19.1719C19.0688 14.6719 18.9844 14.7563 18.9844 14.8594V18.4688H5.01562V14.8594C5.01562 14.7563 4.93125 14.6719 4.82812 14.6719H3.42188C3.31875 14.6719 3.23438 14.7563 3.23438 14.8594V19.5C3.23438 19.9148 3.56953 20.25 3.98438 20.25H20.0156C20.4305 20.25 20.7656 19.9148 20.7656 19.5V14.8594C20.7656 14.7563 20.6812 14.6719 20.5781 14.6719Z" fill="#8C8C8C"/>
                </svg>
            </a>
          }
        
          @if (node.upload) {
            <button (click)="uploadFile(node)">
              <i class="bi bi-upload"></i> Upload
            </button>
          }
        </div>
      </mat-tree-node>
      <!-- Nested node definition for nodes with children -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <div class="mat-tree-node">
          <button class="btn btn-light" (click)="toggleNode(node)">
            <i class="bi {{ treeControl.isExpanded(node) ? 'bi-chevron-down' : 'bi-chevron-right' }}"></i>
          </button>
          {{ node.name }}
        </div>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  
    <!-- Image Preview Section -->
    @if (selectedImageUrl) {
      <div>
        <h3>Image Preview</h3>
        <img [src]="selectedImageUrl" alt="Selected Image" />
        <button (click)="closeImagePreview()">Close</button>
      </div>
    }
  </mat-dialog-content>
  <div class="bottom">
    <div class="button-container">
      <button class="cancel-button" (click)="closeDialog()">Cancel</button>
      <button class="create-button" (click)="createTemplate()">Create Template</button>
    </div>
  </div>
  
  </div>