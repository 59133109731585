import { inject, Injectable } from '@angular/core';
import { TbtStorageService } from '@app/shared/services/tbt-storage.service';
import { jwtDecode } from 'jwt-decode';

interface CustomJwtPayload {
  email: string;
  // Add other properties if needed
}

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private readonly tbtStorageService = inject(TbtStorageService);

  getToken(): string {
    return this.tbtStorageService.getItem('idp_id_token', true) ?? '';
  }

  getUserEmail() {
    const token = this.getToken();
    if (token) {
      return jwtDecode<CustomJwtPayload>(token).email ?? '';
    }
    return '';
  }
}
