import { isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { propertiesReducer } from './properties.reducer';
import { sourceTreeReducer } from './standardise-design/sourcetree.reducer';
import { targetTreeReducer } from './standardise-design/targettree.reducer';

export interface State {

}

export const reducers: ActionReducerMap<State> = {
  properties: propertiesReducer,
  sourcetree: sourceTreeReducer,
  targettree: targetTreeReducer
};


export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];
