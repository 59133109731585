import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ListviewComponent } from './listview/listview.component';

@Directive({
  selector: '[appHoverPreview]',
  standalone: true,
})
export class HoverPreviewDirective {
  @Input('appHoverPreview') imageSrc: string = '';

  constructor(
    private elementRef: ElementRef,
    private parent: ListviewComponent
  ) { }

  @HostListener('mouseenter') onMouseEnter() {
    this.parent.showPreview(this.imageSrc);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.parent.hidePreview();
  }
}
