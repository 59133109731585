import { Component, Input } from '@angular/core';

@Component({
  selector: 'tbt-tab-item',
  standalone: true,
  imports: [],
  templateUrl: './tbt-tab-item.component.html',
  styleUrl: './tbt-tab-item.component.scss'
})
export class TabItemComponent {
  @Input() title!: string;
  @Input() disabled!: boolean;
  active = false;

}
