import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICognitoAuth } from './verify-token.model';
import { environment } from '@env/environment';
import { EUserRole } from '../models/auth.model';
import { endpoints } from '@app/shared/constants/auth.constants';
import { AuthService } from '../services/auth.service';
import { TbtStorageService } from '@app/shared/services/tbt-storage.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyTokenService {
  private userRole: string | null = null;
  private readonly authService = inject(AuthService);
  private readonly tbtStorageService = inject(TbtStorageService);
  private readonly http = inject(HttpClient);
  
  setUserRole(role: string) {
    this.userRole = role;
    this.tbtStorageService.setItem('userRole', role);
  }

  getUserRole(): string | null {
    return this.userRole ?? this.tbtStorageService.getItem('userRole');
  }

  isTemplateAuthor(): boolean {
    return this.getUserRole() === EUserRole.author;
  }

  isTemplateApprover(): boolean {
    return this.getUserRole() === EUserRole.approver;
  }

  logout() {
    localStorage.clear();
    window.location.href = `${environment.cognito_domain}/logout?client_id=${
      environment.clientId
    }&logout_uri=${encodeURIComponent(environment.redirectUriLogout)}`;
  }

  getAccessToken(authCode: string): Observable<ICognitoAuth> {
    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', environment.clientId);
    body.set('client_secret', environment.clientSecret);
    body.set('redirect_uri', environment.redirectUri);
    body.set('code', authCode);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<ICognitoAuth>(
      environment.cognitoUrl,
      body.toString(),
      { headers }
    );
  }

  getSFAuthToken() {
    return this.http.get(`${this.authService.getAWSUrl()}${endpoints.getSalesforceToken}`);
  }

}
