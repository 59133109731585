import { ElementRef } from '@angular/core';
import { CanvasElement } from '../models/canvas-element';
import { CanvasProperties } from '../models/canvas-properties';

export class EditorHelper {
  public static prepareCanvasElement(
    img: HTMLImageElement,
    scale: number,
    canvas: ElementRef<HTMLCanvasElement>,
    canvasProperties: CanvasProperties,
    image: any,
    pageNumber: number
  ): CanvasElement {
    const { modifiedWidth, modifiedHeight } = this.calculateAspectRatioFit(
      img.width,
      img.height,
      canvasProperties.canvasWidth,
      canvasProperties.canvasHeight
    );
    // const canvasWidth = canvas.nativeElement.width;
    // const canvasHeight = canvas.nativeElement.height;

    const designStaticFile = JSON.parse(image.properties);
    let imageX;
    let imageY;
    let imageWidth;
    let imageHeight;
    let isCustomizable = false;
    if (designStaticFile) {
      const { is_customizable, imageGroup, zoneGroup } = designStaticFile;
      imageX = imageGroup.x;
      imageY = imageGroup.y;
      imageWidth = imageGroup.width;
      imageHeight = imageGroup.height;
      isCustomizable = is_customizable;

      if (is_customizable) {
          imageX = zoneGroup.x;
          imageY = zoneGroup.y;
          imageWidth = zoneGroup.width;
          imageHeight = zoneGroup.height;
        }
      }
    const canvasImage: CanvasElement = {
      id: image.id,
      section_id: image.section_id,
      type: image.file_type,
      x: imageX ?? img.x,
      y: imageY ?? img.y,
      file: img,
      element_name: image.name,
      width: imageWidth ?? modifiedWidth - 10 * scale,
      height: imageHeight ?? modifiedHeight * scale,
      customizable: isCustomizable ?? false,
      element_url: image.url,
      page_number: pageNumber,
      is_deleted:false
    };
    return canvasImage;
  }

  public static getMouseCoordinates(
    event: MouseEvent,
    canvasRect: DOMRect,
    canvasProperties: CanvasProperties
  ) {
    // const mouseX =
    //   (event.clientX - canvasRect.left - canvasProperties.zoomTranslateX);
    // const mouseY =
    //   (event.clientY - canvasRect.top - canvasProperties.zoomTranslateY);
    const mouseX =
      event.clientX;
    const mouseY =
      event.clientY;
    return { mouseX, mouseY };
  }

  public static calculateAspectRatioFit(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
  ) {
    // const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {
      modifiedWidth: srcWidth,
      modifiedHeight: srcHeight,
    };
  }
}