import {
  Component,
  OnInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { Subscription } from 'rxjs';
import { CanvasElement } from '../../models/canvas-element';
import { TemplateLayers } from '../../models/template-layers';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { TemplateService } from '@app/shared/services/template.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [NgOptimizedImage, MatTooltipModule],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss',
})
export class SideBarComponent implements OnInit, OnDestroy {
  private imageAddedSubscription!: Subscription;
  private imageRemovedSubscription!: Subscription;
  private elementsChangedSubscription!: Subscription;

  images: any[] = [];

  availableImages: TemplateLayers[] = [];

  @Input()
  jobCode!: string;
  @Input() set templateLayers(value: TemplateLayers[]) {
    this.images = value;
    this.availableImages = value;
  }

  constructor(private canvasEditorService: CanvasEditorService, private templateService: TemplateService) { }
  ngOnInit() {
    this.imageAddedSubscription = this.canvasEditorService
      .getImageAddedObservable()
      .subscribe((fileName: string) => {
        if (fileName) {
          this.removeImageFromSidebar(fileName);
        }
      });

    this.imageRemovedSubscription = this.canvasEditorService
      .getImageRemovedObservable()
      .subscribe((fileName: string) => {
        if (fileName) {
          this.addImageToSidebar(fileName);
        }
      });

    // this.elementsChangedSubscription = this.canvasEditorService
    //   .getElementsChangedObservable()
    //   .subscribe((elements: CanvasElement[]) => {
    //     this.updateAvailableImages(elements);
    //   });
    let elements = this.canvasEditorService.getTemplateConfig.canvas_configuration;
    if (elements){
      this.updateAvailableImages(elements);
    }
    
    this.templateService.getDesigns(this.jobCode).subscribe((response: any) => {
      const urls = response['presigned-urls'];
      if (!urls) {
        return;
      }
      Object.keys(urls).forEach((key) => {
        const fileNameFromDesigns = key.split('/').pop();
        const matchingImageIndex = this.images.findIndex(image => image.url === key);
        if (matchingImageIndex !== -1) {
          const matchingImage = this.images[matchingImageIndex];
          const designUrl = urls[key];
          matchingImage.designUrl = designUrl;
        }
      });
    });
  }

  removeImageFromSidebar(fileName: string) {
    this.availableImages = this.availableImages.filter(image => {
      const match = image.name !== fileName;
      return match;
    });
  }

  addImageToSidebar(fileName: string) {
    const image = this.images.find(img => img.name === fileName);
    if (image) {
      this.availableImages.push(image);
      this.availableImages = [...this.availableImages];
    }
  }

  ngOnDestroy() {
    this.imageAddedSubscription?.unsubscribe();
    this.imageRemovedSubscription?.unsubscribe();
    this.elementsChangedSubscription?.unsubscribe();
  }

  onDragStart(event: DragEvent, image: any): void {
    event.dataTransfer?.setData('text/plain', JSON.stringify(image));
  }

  updateAvailableImages(elements: CanvasElement[]) {
    const usedImages = elements.map(element => element.element_name);
    this.availableImages = this.images.filter(
      image => !usedImages.includes(image.name)
    );
  }
}
