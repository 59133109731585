import { Component, ElementRef, HostListener, inject, OnInit, Renderer2 } from '@angular/core';
import { TemplateService } from '../../../../shared/services/template.service';
import { Router } from '@angular/router';
import { DesignService } from '../../../../shared/services/design.service';
import { FolderStructureComponent } from '../folder-structure/folder-structure.component';
import { MatDialog } from '@angular/material/dialog';
import { VerifyTokenService } from '@app/core/verify-token/verify-token.service';
import { TemplateDesign } from '@app/shared/constants/auth.constants';
import { JwtService } from '@app/core/services/jwt.service';
import { MatIconModule } from '@angular/material/icon';
import { LanguageDialogComponent } from '../language-dialog/language-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SearchService } from '../../services/search.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { SpinnerComponent } from "../../../../shared/components/spinner/spinner.component";

@Component({
  selector: 'app-templates',
  standalone: true,
  imports: [MatIconModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    MatButtonModule, SpinnerComponent],
  templateUrl: './templates.component.html',
  styleUrls: ['../dashboard.scss','./templates.component.scss'],
})
export class TemplatesComponent implements OnInit {
  authService = inject(VerifyTokenService);
  spinnerService = inject(SpinnerService);
  renderer = inject(Renderer2);
  el = inject(ElementRef);
  itemsPerPageOptions = [10, 20, 30, 100];
  templates: any[] = [];
  displayedTemplates: any[] = [];
  filteredTemplates: any[] = [];
  selectedStatus: string = '';
  statusOptions: string[] = ['DRAFT', 'SUBMITTED', 'PUBLISHED', 'REJECTED'];
  filteredStatusOptions: string[] = [];
  itemsPerPage: number = 10;
  currentPage: number = 0;
  totalPages: number = 1;
  currentPageIndex: number = 0;
  statusRoles = {
    templateAuthor: ['DRAFT', 'SUBMITTED', 'PUBLISHED', 'REJECTED'],
    templateApprover: ['SUBMITTED', 'PUBLISHED', 'REJECTED'],
  };
  searchText = '';
  isAuthor = true;

  constructor(
    private router: Router,
    private designService: DesignService,
    private dialog: MatDialog,
    private templateService: TemplateService,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.isAuthor = this.authService.isTemplateAuthor();
    this.fetchTemplates();
    this.updateStatusOptionsBasedOnRole();
    this.searchService.searchText$.subscribe((searchText) => {
      this.onSearchInputChange(searchText);
    });
  }

  jwtService = inject(JwtService);
  fetchTemplates() {
    const user = this.jwtService.getUserEmail();
    this.spinnerService.show();
    this.templateService.getlistTemplates(user, this.isAuthor).subscribe(
      (response: TemplateDesign[]) => {
        if (Array.isArray(response) && response.length > 0) {
          const userRole = this.authService.getUserRole();
          let allowedStatuses: string[] = [];
          if (this.authService.isTemplateAuthor()) {
            allowedStatuses = this.statusRoles.templateAuthor;
          } else if (this.authService.isTemplateApprover()) {
            allowedStatuses = this.statusRoles.templateApprover;
          }
          if ('error' in response) {
            this.spinnerService.hide();
            return;
          }
          this.templates = response.filter((template: TemplateDesign) => {
            const status = template.status ?? '';
            return allowedStatuses.includes(status);
          });
          console.log('Fetched templates:', this.templates);
          this.spinnerService.hide();
          this.displayedTemplates = [...this.templates];
          this.filteredTemplates = [...this.templates];
          this.currentPageIndex = 0;
          this.calculateTotalPages();
          this.updatePaginatedTemplates();
        } else {
          this.templates = [];
          this.currentPageIndex = 0;
          this.totalPages = 1;
          this.displayedTemplates = [];
          console.warn('No templates found or response is not an array.');
        }
        this.spinnerService.hide();
      },
      error => {
        console.error('Error fetching templates:', error);
        this.spinnerService.hide();
      }
    );
  }
  isTemplateAuthor(template: any): boolean {
    return (
      this.authService.isTemplateAuthor() &&
      (template.status === 'DRAFT' || template.status === 'REJECTED')
    );
  }

  isTemplateApprover(template: any): boolean {
    return (
      this.authService.isTemplateApprover() && template.status === 'SUBMITTED'
    );
  }

  openLanguagesDialog(): void {
    const dialogData = this.templates.map(template => ({
      template_id: template.template_id,
      template_language: template.template_language,
      template_name: template.template_name,
    }));

    const dialogRef = this.dialog.open(LanguageDialogComponent, {
      backdropClass:'custom-dialog',
      data: dialogData,
      width: '900px',
      height: '325px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }

  updateStatusOptionsBasedOnRole() {
    const userRole = this.authService.getUserRole();
    if (this.authService.isTemplateAuthor()) {
      this.filteredStatusOptions = this.statusRoles.templateAuthor;
    } else if (this.authService.isTemplateApprover()) {
      this.filteredStatusOptions = this.statusRoles.templateApprover;
    } else {
      this.filteredStatusOptions = this.statusOptions;
    }
  }
  isDropdownOpen: boolean = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  selectStatus(status: string) {
    this.selectedStatus = status;
    this.isDropdownOpen = false;
    if (status === 'All') {
      this.displayedTemplates = [...this.templates];
      this.filteredTemplates = this.displayedTemplates;
      this.currentPageIndex = 0;
      this.calculateTotalPages();
      this.updatePaginatedTemplates();
    } else {
      this.onStatusFilterChange(status);
    }
  }

  // onStatusFilterChange(event: Event) {
  //   this.selectedStatus = (event.target as HTMLSelectElement).value;
  //   this.filterTemplates();
  // }

  onStatusFilterChange(status: string) {
    this.selectedStatus = status;
    this.filterTemplates();
  }

  filterTemplates() {
    if (this.selectedStatus) {
      this.filteredTemplates = this.templates.filter(template => {
        const matchesStatus =
          !this.selectedStatus || template.status === this.selectedStatus;
        return matchesStatus;
      });
    } else {
      this.filteredTemplates = [...this.templates];
    }
    if (this.filteredTemplates.length === 0) {
      this.currentPageIndex = 0;
      this.totalPages = 1;
    } else {
      this.currentPageIndex = 0;
      this.calculateTotalPages();
    }
    this.updatePaginatedTemplates();
  }

  onSearchInputChange(searchTerm: string) {
    searchTerm = searchTerm.toLowerCase();
    this.filteredTemplates = this.templates.filter((template) => {
      const matchesSearch =
        (template.template_name?.toLowerCase() || '').includes(searchTerm) ||
        (template.job_code?.toLowerCase() || '').includes(searchTerm) ||
        (template.template_submitted_date?.toLowerCase() || '').includes(
          searchTerm
        ) ||
        (template.template_approved_date?.toLowerCase() || '').includes(
          searchTerm
        ) ||
        (template.status?.toLowerCase() || '').includes(searchTerm);
      const matchesStatus =
        !this.selectedStatus || template.status === this.selectedStatus;
      return matchesSearch && matchesStatus;
    });
    this.calculateTotalPages();
    this.updatePaginatedTemplates();
  }

  onCardClick(templateId: string, jobCode: string, status: string) {  
    if (this.authService.isTemplateAuthor() && (status === 'REJECTED' || status === 'DRAFT')) {
        this.router.navigate(['/template-builder', templateId, jobCode]);
    } else if (this.authService.isTemplateApprover()) {
      if (status === 'SUBMITTED') {
        this.router.navigate(['/personalized-resource', templateId, jobCode]);
      }
    }
  }

  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'no_image.png';
  }
  onItemsPerPageChange(event: any) {
    this.itemsPerPage = parseInt(event.target.value, 10);
    this.currentPage = 0;
    this.calculateTotalPages();
    if (this.filteredTemplates.length === 0) {
      this.currentPage = 0;
      this.totalPages = 1;
    }
    this.updatePaginatedTemplates();
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(
      this.filteredTemplates.length / this.itemsPerPage
    );
  }

  updatePaginatedTemplates() {
    const startIndex = this.currentPageIndex * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedTemplates = this.filteredTemplates.slice(
      startIndex,
      endIndex
    );
  }
  getVisiblePages(): number[] {
    const pages = [];
    const total = this.totalPages;

    if (total <= 5) {
      for (let i = 0; i < total; i++) {
        pages.push(i);
      }
    } else {
      pages.push(0);

      if (this.currentPageIndex > 1) {
        pages.push(-1);
      }

      const start = Math.max(1, this.currentPageIndex - 1);
      const end = Math.min(this.currentPageIndex + 1, total - 2);

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (this.currentPageIndex < total - 2) {
        pages.push(-1);
      }

      pages.push(total - 1);
    }

    return pages;
  }

  selectPage(index: number): void {
    if (index >= 0 && index < this.totalPages) {
      this.currentPageIndex = index;
      this.updatePaginatedTemplates();
    }
  }

  nextPage(): void {
    if (!this.isLastPage()) {
      this.currentPageIndex++;
      this.updatePaginatedTemplates();
    }
  }

  previousPage(): void {
    if (!this.isFirstPage()) {
      this.currentPageIndex--;
      this.updatePaginatedTemplates();
    }
  }

  isFirstPage(): boolean {
    return this.currentPageIndex === 0;
  }

  isLastPage(): boolean {
    return this.currentPageIndex === this.totalPages - 1;
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdown = this.el.nativeElement.querySelector('.dropdown');
    const dropdownToggle = this.el.nativeElement.querySelector('.dropdown-selected');
    if (dropdown && !dropdown.contains(target) && !dropdownToggle.contains(target)) {
      this.isDropdownOpen = false;
    }
  }
}
