import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { TemplateService } from 'src/app/shared/services/template.service';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-customizable-click-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatButton,
    MatIcon,
    MatIconModule,
  ],
  templateUrl: './customizable-click-dialog.component.html',
  styleUrl: './customizable-click-dialog.component.scss',
})
export class CustomizableClickDialogComponent implements OnInit {
  isSelectButtonDisabled = true;
  selectedImageIndex: number | null = null;
  presignedurls: { [key: string]: string } = {};
  matchingImages: string[] = [];

  constructor(
    private templateService: TemplateService,
    private spinnerService: SpinnerService,
    public dialogRef: MatDialogRef<CustomizableClickDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { images: string[], title: string, templateName: string, job_code: string,
       elements: any, elementIndex: number }
  ) {
    console.log('Images passed to the dialog:', data.images);
    if (this.data.elementIndex >= 0) {
      this.getSelectedImage();
    }
  }
  getSelectedImage() {
    const selectedImage = this.data.elements[this.data.elementIndex].element_url;
    this.data.images.forEach((image, index) => {
      if (image === selectedImage) {
        this.selectedImageIndex = index;
      }
    });
  }

  ngOnInit(): void {
    this.getPresignedImageURL();
  }

  getPresignedImageURL(): void {
    this.spinnerService.show();
    this.templateService.getDesigns(this.data.job_code).subscribe(
      (response: any) => {
        this.presignedurls = response['presigned-urls'];
        this.filterMatchingImages();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        console.error('Error fetching presigned URLs:', error);
      }
    );
  }

  filterMatchingImages(): void {
    this.data.images.forEach((image) => {
      Object.keys(this.presignedurls).forEach((key) => {
        if (key.includes(image)) {
          const matchedUrl = this.presignedurls[key];
          this.matchingImages.push(matchedUrl);
        }
      });
    });
  }

  onImageClick(index: number): void {
    this.selectedImageIndex = index;
    this.isSelectButtonDisabled = false;
  }

  onselectClick(): void {
    if (this.selectedImageIndex !== null) {
      const selectedImageKey = this.data.images[this.selectedImageIndex];
      const matchedPresignedUrl = this.presignedurls[selectedImageKey];
      
      if (matchedPresignedUrl) {
          const url = new URL(matchedPresignedUrl);
          this.dialogRef.close(url);
      } else {
        console.error('No matching presigned URL found for the selected image');
      }
    }
  }
  
  oncancelClick(): void {
    this.dialogRef.close();
  }
}
