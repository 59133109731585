<h2 mat-dialog-title class="dialog-title">
  {{ getTitle() }}
  <button
    mat-icon-button
    (click)="oncancelClick()"
    aria-label="Close"
    class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  @if (data.type === 'confirmContinue') {
    <p>
      <strong class="resourceName">{{ data.resourceName }}</strong>
    </p>
    <p class="savechangesmessage">{{ labels.confirmContinue.message }}</p>
  }
  @if (data.type === 'approveSuccess') {
    <p>
      <strong class="resourceName">{{ data.resourceName }}</strong>
    </p>
  }
  @if (data.type === 'confirmCancel') {
    <p>
      <strong class="resourceName">{{ data.resourceName }}</strong>
    </p>
    <p class="savechangesmessage">{{ labels.confirmCancel.message }}</p>
  }
  @if (data.type === 'confirmReject') {
    <p>
      <strong class="templateName">{{ data.resourceName }}</strong>
    </p>
    <mat-label class="label">{{ labels.confirmReject.label }}</mat-label>
    <mat-form-field appearance="fill" class="custom-textarea">
      <textarea
        matInput
        [(ngModel)]="comments"
        rows="1"
        maxlength="150"></textarea>
    </mat-form-field>
  }
  @if (data.type === 'rejectSuccess') {
    <p>
      <strong class="resourceName">{{ data.resourceName }}</strong>
    </p>
    <p class="rejectcomments">{{ data.rejectComments }}</p>
  }
  @if (error) {
    <p class="error-message">{{ error }}</p>
  }
</mat-dialog-content>

<mat-dialog-actions>
  @if (data.type === 'confirmContinue') {
    <button mat-button class="confirm" (click)="onYesClick()">
      {{ labels.confirmContinue.approveText }}
    </button>
    <button mat-button class="cancel" (click)="oncancelClick()">
      {{ labels.confirmContinue.cancelText }}
    </button>
  }

  @if (data.type === 'approveSuccess' || data.type === 'rejectSuccess') {
    <button mat-button class="ok" (click)="onOkClick()">
      {{ labels[data.type].okText }}
    </button>
  }

  @if (data.type === 'confirmReject') {
    <button mat-button class="confirm" (click)="onYesClick()">
      {{ labels.confirmReject.rejectText }}
    </button>
    <button mat-button class="cancel" (click)="oncancelClick()">
      {{ labels.confirmReject.cancelText }}
    </button>
  }
  <div class="left-actions">
    @if (data.type === 'confirmCancel') {
      <button mat-button class="confirm" (click)="onYesClick()">
        {{ labels.confirmCancel.yesText }}
      </button>
      <button mat-button class="cancel" (click)="onNoClick()">
        {{ labels.confirmCancel.noText }}
      </button>
    }
  </div>
</mat-dialog-actions>
