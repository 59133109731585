<!-- <nav class="header"> -->
<!--  <div class="header-container">-->
<!--    <span>Template Building Tool</span>-->
<!--  </div>-->
  <!-- <ul>
    @for (button of navButtons; track button.id) {
      <li>
        <a [routerLink]="button.route" [routerLinkActive]="'active'">
          {{ button.label }}
        </a>
      </li>
    }
  </ul>
</nav> -->
<div class="main-container">
  <app-header (searchTextChange)="onSearchTextChange($event)"></app-header>
  <!-- <app-spinner></app-spinner> -->
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>

