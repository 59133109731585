<div class="job-code-header">
  <span>{{ jobCode }}</span>
  <span class="header-buttons">
    <button mat-button class="properties-button" (click)="displayProperties()">Properties</button>
    <button mat-button class="save-button" (click)="onSave()">Save</button>
    <button mat-button class="cancel-button" (click)="goBack()">Cancel</button>
  </span>
</div>
<app-spinner></app-spinner>
<div [class]="'split-screen'">
  <div class="left-pane">
    <span class="header">Standard folder view</span>
    <app-tree-structure
      [treeControl]="leftTreeControl"
      [dataSource]="leftDataSource"></app-tree-structure>
  </div>
  <!--  @if (showSplitScreen) {-->
  <div class="right-pane">
    <div class="d-flex justify-content-between align-items-center">
      <span class="header">Design Bundle</span>
      <button mat-button class="standardize-button" (click)="onStandardize()">
        Standardize
      </button>
    </div>
    <app-tree-structure
      [treeControl]="rightTreeControl"
      [dataSource]="rightDataSource"></app-tree-structure>
  </div>
  <!--  }-->
</div>
