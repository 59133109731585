import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { VerifyTokenService } from '@app/core/verify-token/verify-token.service';

@Component({
  selector: 'app-sign-out-alert',
  standalone: true,
  imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  templateUrl: './sign-out-alert.component.html',
  styleUrl: './sign-out-alert.component.scss'
})
export class SignOutAlertComponent {

  authService = inject(VerifyTokenService);
  readonly dialogRef = inject(MatDialogRef<SignOutAlertComponent>);

  constructor() { }

  logout() {
    this.authService.logout();
  }

}
