@if (show) {
    <div class="popup">
      <span class="close" (click)="onClose()">&times;</span>
      <div id="zoomIcons">
        <button type="button" (click)="zoomIn()">Zoom In</button>
        <button type="button" (click)="zoomOut()">Zoom Out</button>
      </div>
      <img
        class="popup-content"
        id="template-preview"
        alt="template-preview"
        [src]="imageSrc" />
      </div>
    }
  