import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PopupLabels } from '../../utils/editor-util';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatButton,
    MatIcon,
    SpinnerComponent
  ],
  templateUrl: './popup.component.html',
  styleUrl: '../dialogs.scss',
})
export class PopupComponent {
  labels = PopupLabels;
  comments: string = '';
  loading: boolean = false;
  error: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: 'confirmSubmit' | 'submissionSuccess' | 'approveSuccess' | 'confirmReject'; 
      contentTemplate?: any;
      comments?: string;
      resourceName?: string;
      onYes?: (comments?: string) => void;
      onNo?: () => void;
      onOk?: () => void;
    }
  ) {
    if (this.data.comments) {
      this.comments = this.data.comments;
    }
  }

  getTitle(): string {
    return this.labels[this.data.type].title;
  }

  getContentTemplate(): any {
    return this.data.contentTemplate;
  }

  get cancelText(): string {
    return (this.labels[this.data.type] as any)?.cancelText || 'Cancel';
  }

  get yesText(): string {
    return (this.labels[this.data.type] as any)?.yesText || 'Yes';
  }

  get okText(): string {
    return (this.labels[this.data.type] as any)?.okText || 'Ok';
  }

  oncancelClick(): void {
    if (this.data.onNo) {
      this.data.onNo();
    }
    this.dialogRef.close();
  }

  onYesClick(): void {
    try {
      if (this.data.onYes) {
        this.data.onYes(this.comments);
      }
      this.dialogRef.close();
    } catch (error) {
      this.error = 'An error occurred. Please try again.';
    }
  }

  onOkClick(): void {
    if (this.data.onOk) {
      this.data.onOk();
    }
    this.dialogRef.close();
  }

  checkForErrors() {
    //
  }
}
