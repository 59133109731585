import { createSelector } from '@ngrx/store';
import { AppState } from '../models';
import { PropertiesState } from '../models/properties.model';

export const selectProperties = (state: AppState) => state.properties;

export const selectDimensions = createSelector(
  selectProperties,
(state: PropertiesState) => state.dimension
);

export const isCustomizable = createSelector(
  selectProperties,
(state: PropertiesState) => state.customizable
);