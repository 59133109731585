import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { TreeNodeComponent } from "./tree-node/tree-node.component";
import { TreeNode } from '../../model/tree-node';
import { NestedTreeControl } from '@angular/cdk/tree';

@Component({
  selector: 'app-tree-structure',
  standalone: true,
  templateUrl: './tree-structure.component.html',
  styleUrl: './tree-structure.component.scss',
  imports: [MatTreeModule, MatIconModule, MatButtonModule, TreeNodeComponent],
})
export class TreeStructureComponent {
  @Input()
  treeControl: NestedTreeControl<TreeNode> = new NestedTreeControl<TreeNode>((node) => node.children);

  @Input()
  dataSource: MatTreeNestedDataSource<TreeNode> = new MatTreeNestedDataSource<TreeNode>();;
  
  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  trackByFn(_index: number, item: TreeNode) { return item; }

}
