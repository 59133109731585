import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable, switchMap } from 'rxjs';
import { EUserRole, IUserRole } from '../models/auth.model';
import { endpoints } from '@app/shared/constants/auth.constants';
import { TbtStorageService } from '@app/shared/services/tbt-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userRole: string | null = null;
  private approverName: string | null = null;
  private readonly tokenSubject = new BehaviorSubject<string | null>(null);
  private readonly http = inject(HttpClient);
  private readonly tbtStorageService = inject(TbtStorageService);

  setUserRole(role: string) {
    this.userRole = role;
    this.tbtStorageService.setItem('userRole', role);
  }

  getUserRole(): string | null {
    return this.userRole ?? this.tbtStorageService.getItem('userRole');
  }

  setApproverName(approverName: string) {
    this.approverName = approverName;
    this.tbtStorageService.setItem('approverName', approverName);
  }

  getApproverName(): string | null {
    return this.approverName ?? this.tbtStorageService.getItem('approverName');
  }

  isTemplateAuthor(): boolean {
    return this.getUserRole() === EUserRole.author;
  }

  isTemplateApprover(): boolean {
    return this.getUserRole() === EUserRole.approver;
  }

  isCustomer(): boolean {
    return this.getUserRole() === EUserRole.customer; 
  }

  isAccountExecutive(): boolean {
    return this.getUserRole() === EUserRole.account_executive;
  }

  getAWSUrl(): string {
    let url = environment.apiGatewayUrl;
    const idp = this.tbtStorageService.getItem('idp', true);
    if (idp && idp === 'SF') {
     url = environment.apiProxyApi; 
    }
    return url;
  }

  getSalesForceAccessToken(): Observable<any> {
    return this.http.get(`${this.getAWSUrl()}${endpoints.getSalesforceToken}`).pipe(
      switchMap((res: any) => {
        if (res.statusCode === 200) {
          const { access_token, instance_url } = res.body;
          this.tbtStorageService.setItem('access_token', access_token, true);
          this.tbtStorageService.setItem('instance_url', instance_url, true);
          this.tokenSubject.next(access_token);
        }
        return this.tokenSubject.asObservable();
      })
    );
  }

  getToken() {
    return this.tokenSubject.asObservable();
  }

  getNewToken(): Observable<string> {
    return this.getSalesForceAccessToken();
  }

  getUserRoleForTBT(userName: string): Observable<IUserRole> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const path = '/services/data/v58.0/query/';
    const query = `q=SELECT+MSD_CORE_User_Name__c,+MSD_CORE_User_Role__c,+MSD_CORE_HEQ_Approver_Id__c+FROM+MSD_CORE_HEQ_TBT_App_User_Detail__c+WHERE+MSD_CORE_User_Name__c='${encodeURIComponent(userName)}'`;
    return this.http.get<IUserRole>(`${instanceUrl}${path}?${query}`);
  }
}
