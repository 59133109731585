import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TbtStorageService } from '../services/tbt-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private readonly router = inject(Router);
  private readonly tbtStorageService = inject(TbtStorageService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.tbtStorageService.getItem('access_token', true);

    const url = state.url;
    const idpParam = this.getQueryParam(url, 'idp');

    if (idpParam) {
      return this.checkIdpAuthentication(idpParam);
    } else {
      if (token) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    }
  }

  private getQueryParam(url: string, param: string): string | null {
    const queryParams = new URLSearchParams(url.split('?')[1]);

    // get the params and store in local storage

    const idp = queryParams.get('idp');
    const authToken = queryParams.get('authtoken');
    const role = queryParams.get('role');
    const jobCode = queryParams.get('jobcode');
    const templateId = queryParams.get('templateid');

    if (idp) {
      this.tbtStorageService.setItem('idp', idp, true);
    }
    if (authToken) {
      this.tbtStorageService.setItem('authToken', authToken, true);
    }
    if (role) {
      this.tbtStorageService.setItem('userRole', role);
    }
    if (jobCode) {
      this.tbtStorageService.setItem('jobCode', jobCode);
    }
    if (templateId) {
      this.tbtStorageService.setItem('templateId', templateId);
    }

    return queryParams.get(param);
  }

private checkIdpAuthentication(idp: string): boolean {
    if (idp && idp === 'SF') {
      return true;
    } else {
      // this.router.navigate(['/']);
      return false;
    }
}

}
export const authGuard: CanActivateFn = (route, state) => {
  return true;
};
