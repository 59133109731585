import { ChangeDetectionStrategy, Component, inject, OnInit, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { CanvasEditorService } from '../../../services/canvas-editor.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IElementConfig, IOptions } from '@app/features/template-builder-tool/models/config-mapping.model';
import { ModuleAsset } from '@app/features/template-builder-tool/models/module-assets';
import { TemplateService } from '@app/shared/services/template.service';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-config-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatSelectModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './config-dialog.component.html',
  styleUrl: './config-dialog.component.scss'
})
export class ConfigDialogComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  configElement!: IElementConfig;
  configOption: IOptions = {
    "label": "",
    "thumbnail": "",
    "checked": false,
    "order": 0,
    "is_deleted": false
  };
  optionArray!: string[];
  moduleData: Array<ModuleAsset> = [];
  canvasConfiguration: any;
  templateData: any;
  isUpdate = false;
  filteredAssets: { key: string, url: string }[] = [];
  jobCode: string | null | undefined;

  constructor(
    private canvasEditorService: CanvasEditorService,
    private templateService: TemplateService,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: { moduleData: Array<ModuleAsset>, moduleConfig: Array<IElementConfig>, canvasConfiguration: any, element: any, jobCode: string }
  ) { }

  ngOnInit(): void {
    this.setForm();
    this.loadDesigns();
  }

  loadDesigns() {
    this.spinnerService.show();
    
    if (this.configElement.module) {
      this.templateData = this.canvasEditorService.getTemplateConfig;
      console.log(this.templateData);
  
      this.templateService.getDesigns(this.data.jobCode).subscribe((response: any) => {
          const urls = response['presigned-urls'];
          
          this.filteredAssets = (this.configElement.module?.assets || [])
            .map(asset => ({
              key: asset,
              url: urls[asset] || asset
            }))
            .filter(item => item.url);
          
          this.spinnerService.hide();
        },
        (error) => {
          console.error("Error loading designs:", error);
          this.spinnerService.hide();
        }
      );
    } else {
      this.spinnerService.hide(); 
    }
  }

  isOptionValid(): boolean {
    const { label, thumbnail} = this.configOption;
    const thumbnailValue = Array.isArray(thumbnail) ? thumbnail.join(', ') : thumbnail || '';
    return label.trim() !== '' && thumbnailValue.trim() !== '';
  }

  onTypeChange() {
    this.loadDesigns();
  }

  updateThumbnail(selectedKey: string) {
    this.configOption.thumbnail = selectedKey;
  }

  getAssetUrl(key: string): string | undefined {
    const asset = this.filteredAssets.find(asset => asset.key === key);
    return asset?.url;
  }

  async submitForm() {
    let masterTemplateConfig = this.getMasterTemplateConfig();
    if (this.configElement?.module) {
      this.configElement.module.is_deleted = false;
      this.configElement.module.is_dirty = true;
      if (!this.isUpdate) {
        //  this.configElement.module.forEach(element => {
        delete this.configElement.module.id;
        //})
      }
    }

    if (this.configElement.type.toString() == "image") { //Added dummy options to image type as the service does not support config without options
      this.configElement.options?.push({
        label: '',
        thumbnail: '',
        checked: false,
        order: 0,
        is_deleted: false,
      })
    }


    this.canvasEditorService.setModuleConfiguration(this.configElement);
    let response = await this.canvasEditorService.doSave(masterTemplateConfig, 'save', '', 'DRAFT', 'module-config'); 
    if(response){
      this.dialog.closeAll();
    }
  }

  setForm() {
    this.isUpdate = false;
    this.jobCode = this.data.jobCode;
    this.moduleData = this.data.moduleData;
    if (this.data.moduleData?.length > 0) {
      this.moduleData.forEach(element => {
        delete element.id;
      })
    }
    if (this.data.element) {
      let { type, ...configElement } = this.data.element;
      this.configElement = configElement
      this.configElement = { ...configElement, type: (type || '').toLowerCase() };
      this.configElement.is_deleted = false;
      this.configElement.is_dirty = true;
      this.isUpdate = true;
    } else {
      this.configElement = {
        label: "",
        type: "dropdown",
        module: undefined,
        options: [],
        helpText: "",
        validationError: "",
        hasImages: false,
        noOfSelection: 1,
        is_deleted: false,
        is_dirty: true
      };
    }
    if (!this.configElement.options) {
      this.configElement.options = [];
    }
  }
  getMasterTemplateConfig(): any {
    return this.canvasEditorService.getTemplateConfig;
  }

  addOption() {
    const configOptionObject: IOptions = {
      label: this.configOption.label,
      thumbnail: Array.isArray(this.configOption.thumbnail) ? JSON.stringify(this.configOption.thumbnail) : this.configOption.thumbnail,
      checked: false,
      order: this.configOption.order,
      is_deleted: false,
      is_dirty: true
    };

    this.configElement.options?.push(configOptionObject);
    this.configOption = {
      label: '',
      thumbnail: '',
      checked: false,
      order: 0,
      is_deleted: false,
    };
  }
}