import { CommonModule } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/shared/services/template.service';
import { LanguageMapper } from '../../model/language-dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-language-dialog',
  standalone: true,
  imports: [CommonModule,MatFormField,MatLabel,MatOption,FormsModule,MatSelect,MatIcon,MatAutocompleteModule,MatInput],
  templateUrl: './language-dialog.component.html',
  styleUrl: './language-dialog.component.scss'
})
export class  LanguageDialogComponent implements OnInit {
  private readonly spinnerService = inject(SpinnerService);
  languageMappers: LanguageMapper[] = [];
  selectedLanguage: string | null = null; 
  selectedLanguages: string[] = []; 
  availableLanguages: { template_name: string, template_id: string, template_language: string | null }[] = [];
  filteredLanguages: { template_name: string, template_id: string, template_language: string | null }[] = [];
  isDropdownVisible: boolean = false; 
  templateData!: any[]; 
  selectedMapper: LanguageMapper | null = null;

  constructor(
    private templateService: TemplateService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LanguageDialogComponent> 
  ) {
    this.templateData = data;
  }

  ngOnInit(): void {
    this.loadLanguageMappers();
    this.logTemplateData();
    this.initializeAvailableLanguages();
    this.filteredLanguages = this.availableLanguages; 
  }

  loadLanguageMappers(): void {
    this.spinnerService.show();
    this.templateService.getlistLanguageMappers().subscribe(
      (response: LanguageMapper[]) => {
        this.languageMappers = response;
        this.spinnerService.hide();
        console.log('Language Mappers Response:', this.languageMappers);
      },
      (error) => {
        console.error('Error fetching language mappers:', error);
        this.spinnerService.hide();
      }
    );
  }

  logTemplateData(): void {
    console.log('Template Data:', this.templateData);
  }

  initializeAvailableLanguages(): void {
    this.availableLanguages = this.templateData.map(template => ({
      template_name: template.template_name,
      template_id: template.template_id,
      template_language: template.template_language
    }));
  }

  toggleLanguageDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible; 
  }

  onSelectMapper(mapper: LanguageMapper): void {
    this.selectedMapper = mapper; 
    this.isDropdownVisible = true; 
    this.selectedLanguages = this.getMappedTemplateIds(mapper);
    this.selectedLanguage = mapper.language_mapper_name; // Set selected language to mapper name
    console.log('Selected Mapper:', mapper);
    console.log('Selected Languages from Mapper:', this.selectedLanguages);
  }

  getMappedTemplateIds(mapper: LanguageMapper): string[] {
    // Extract template IDs from the mapper and match with the available templates.
    const mapperTemplateIds = mapper.template_ids.map(id => id.split(':')[0]);
    return this.availableLanguages
      .filter(template => mapperTemplateIds.includes(template.template_id))
      .map(template => template.template_id);
  }

  onLanguageChange(): void {
    const selectedTemplate = this.availableLanguages.find(
      template => template.template_name === this.selectedLanguage
    );
    if (selectedTemplate && !this.selectedLanguages.includes(selectedTemplate.template_id)) {
      this.selectedLanguages.push(selectedTemplate.template_id);
      console.log('Selected Language Template ID:', selectedTemplate.template_id);
    }
    console.log('Selected Language from Combo Box:', this.selectedLanguage);
  }

  filterLanguages(): void {
    const filterValue = this.selectedLanguage?.toLowerCase();
    this.filteredLanguages = this.availableLanguages.filter(language => 
      language.template_name.toLowerCase().includes(filterValue!)
    );
  }

  onSave(): void {
    const templateIds = [...new Set(this.selectedLanguages)].join(',');
    const languageToSave = this.selectedMapper ? this.selectedMapper.language_mapper_name : this.selectedLanguage;

    if (languageToSave && this.selectedLanguages.length > 0) {
      this.templateService.getLanguageMappers(templateIds, languageToSave).subscribe(
        (response) => {
          console.log('Response from getLanguageMappers:', response);
          this.loadLanguageMappers();
        },
        (error) => {
          console.error('Error fetching language mappers:', error);
        }
      );
    } else {
      console.warn('Please select a language and at least one template ID before saving.');
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}