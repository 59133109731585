export interface Design {
    method_code?: string;
    user_id: string | null;
    thumbnail: string | null;
    template_type: string | null;
    status: string;
    static_files: any;
    Modules: any;
    mapping_guidelines_file: string | null;
    language: string;
    job_code: string;
    design_name: string;
    design_file: string | null;
    combined_pdf: string | null;
    canvas_properties: string;
  }

  export const DESIGN_STATUS = {
    RAW: 'RAW'
  };
