import { HttpClient } from '@angular/common/http';
import { Component,ElementRef,HostListener,OnInit, Renderer2} from '@angular/core';
import { DesignsService } from '../../services/designs.service';
import { Design } from '../../model/design';
import { Router } from '@angular/router';
import { FolderStructureComponent } from '../folder-structure/folder-structure.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-designs',
  standalone: true,
  imports: [CommonModule, FormsModule, SpinnerComponent],
  templateUrl: './designs.component.html',
  styleUrls: ['../dashboard.scss','./designs.component.scss']
})
export class DesignsComponent implements OnInit {
  designs: any[] = [];
  filteredDesigns: any[] = [];
  displayedDesigns: any[] = [];
  searchText = '';
  selectedStatus = 'All';
  isDropdownOpen = false;
  itemsPerPage: number = 10;
  totalPages: number = 1;
  currentPage: number = 1;
  currentPageIndex: number = 0;
  itemsPerPageOptions = [10, 20, 30, 100];
  filteredStatusOptions = ['RAW', 'STANDARDIZED', 'IN_USE'];
  constructor(
    private http: HttpClient,
    private designsService: DesignsService,
    private router: Router,
    private dialog: MatDialog,
    private searchService: SearchService,
    private spinnerService:SpinnerService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.loadDesigns();
    this.searchService.searchText$.subscribe((searchText) => {
      this.searchText = searchText; 
      this.filterDesigns(); 
      console.log('Design Component Search Text:', searchText); 
    });
  }

  loadDesigns() {
    this.spinnerService.show(); 
    this.designsService.listDesigns().subscribe(
        data => {
          this.designs = data;
          this.filteredDesigns = this.designs;
          this.spinnerService.hide();
          this.calculateTotalPages();
          this.updatePaginatedDesigns();
        },
        error => {
          this.designs = [];
          this.currentPageIndex = 0;
          this.totalPages = 1;
          this.displayedDesigns = [];
          console.error('Error fetching designs:', error);
          this.spinnerService.hide();
        }
      );
  }

  trackByDesignName(index: number, design: Design): string {
    return design.design_name;
  }
  get paginatedDesigns(): any[] {
    return this.displayedDesigns;
  }
  
  openDialog(design: any = null) {
    this.dialog.open(FolderStructureComponent, {
      backdropClass:'custom-dialog',
      data: { design },
      width: '800px',
    });
  }
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectStatus(status: string): void {
    this.selectedStatus = status;
    this.isDropdownOpen = false;
    // this.loadDesigns();
    this.filterDesigns();
    this.currentPageIndex = 0;
  }
  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'no_image.png';
  }

  onCardClick(design: Design) {
    if (design.status === 'STANDARDIZED' || design.status === 'RAW') {
      this.router.navigate(['/dashboard/standardize-designs', design.job_code]);
    } else {
      console.log('Dialog not allowed for design with status:', design.status);
    }
  }

  // onSearchInputChange(event: Event) {
  //   const target = event.target as HTMLInputElement;
  //   this.searchText = target.value.toLowerCase();
  //   this.filterDesigns();
  // }
  onItemsPerPageChange(event: any) {
    this.itemsPerPage = +event.target.value;
    this.currentPageIndex = 0;
    this.calculateTotalPages();
    if (this.filteredDesigns.length === 0) {
      this.currentPage = 0;
      this.totalPages = 1;
    }
    this.updatePaginatedDesigns();
  }

  filterDesigns() {
    this.filteredDesigns = this.designs.filter(design => {
      const matchesSearchText = design.job_code.toLowerCase().includes(this.searchText) ||
      design.design_name.toLowerCase().includes(this.searchText);
      const matchesStatus =
        this.selectedStatus === 'All' || design.status === this.selectedStatus;
      return matchesSearchText && matchesStatus;
    });
    this.currentPageIndex = 0;
    this.calculateTotalPages();
    this.updatePaginatedDesigns();
  }

  calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.filteredDesigns.length / this.itemsPerPage);
      if (this.currentPageIndex >= this.totalPages) {
      this.currentPageIndex = this.totalPages > 0 ? this.totalPages - 1 : 0;
    }
}
  updatePaginatedDesigns() {
    const startIndex = this.currentPageIndex * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.displayedDesigns = this.filteredDesigns.slice(startIndex, endIndex);
  }
  getVisiblePages(): number[] {
    const pages = [];
    const total = this.totalPages;

    if (total <= 5) {
      for (let i = 0; i < total; i++) {
        pages.push(i);
      }
    } else {
      pages.push(0);

      if (this.currentPageIndex > 1) {
        pages.push(-1);
      }

      const start = Math.max(1, this.currentPageIndex - 1);
      const end = Math.min(this.currentPageIndex + 1, total - 2);

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (this.currentPageIndex < total - 2) {
        pages.push(-1);
      }

      pages.push(total - 1);
    }

    return pages;
  }

  selectPage(index: number): void {
    if (index >= 0 && index < this.totalPages) {
      this.currentPageIndex = index;
      this.updatePaginatedDesigns();
    }
  }

  previousPage() {
    if (!this.isFirstPage()) {
      this.currentPageIndex--;
      this.updatePaginatedDesigns();
    }
  }

  nextPage() {
    if (!this.isLastPage()) {
      this.currentPageIndex++;
      this.updatePaginatedDesigns();
    }
  }
  isFirstPage(): boolean {
    return this.currentPageIndex === 0;
  }

  isLastPage(): boolean {
    return this.currentPageIndex === this.totalPages - 1;
  }

  @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdown = this.el.nativeElement.querySelector('.dropdown');
    const dropdownToggle = this.el.nativeElement.querySelector('.dropdown-selected');
    if (dropdown && !dropdown.contains(target) && !dropdownToggle.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

}
