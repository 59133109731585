import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TreeNode } from '../../../model/tree-node';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/models';
import { selectJobId, selectTargetDesignStructure } from 'src/app/shared/store/selectors/design.selector';
import { createFolder, removeTargetElement, uploadDesignFile } from 'src/app/shared/store/actions/standardise-design.actions';
import { extractPathFromNode } from '@app/shared/helpers/tree-helpers';
import { FileService } from '@app/features/dashboard/services/file.service';
import { MatDialog } from '@angular/material/dialog';
import { DesignPropertiesComponent } from '../../standardise-designs/components/design-properties/design-properties.component';

@Component({
  selector: 'app-tree-node',
  standalone: true,
  imports: [[CommonModule, MatTreeModule, MatIconModule, MatButtonModule]],
  templateUrl: './tree-node.component.html',
  styleUrl: './tree-node.component.scss'
})
export class TreeNodeComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  @Input() node: any;

  @Input()
  treeControl: NestedTreeControl<TreeNode> = new NestedTreeControl<TreeNode>(
    node => node.children
  );

  hasChild = false;

  jobId = '';

  dropPlaceholder = false;

  showAddFolderForm = false;
  designData :any;

  constructor(
    private fileService: FileService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.hasChild = this.node.children;
    this.store.select(selectJobId).subscribe(jobId => {
      this.jobId = jobId;
    });
    this.store.select(selectTargetDesignStructure).subscribe((data: any) => {
      this.designData = data;
    });
  }

  onDragStart(event: DragEvent, node: TreeNode) {
    event.dataTransfer?.setData('application/json', JSON.stringify(node));
  }

  onDragEnd(evnet: DragEvent) {
    this.dropPlaceholder = false;
  }

  onDragOver(event: DragEvent, node: TreeNode) {
    if (node.droppable) {
      event.preventDefault();
      this.dropPlaceholder = true;
    }
  }

  onDrop(event: DragEvent, targetNode: TreeNode) {
    const sourceNode = JSON.parse(
      event.dataTransfer?.getData('application/json') + ''
    );
    this.dropPlaceholder = false;
    const filePath = extractPathFromNode(
      targetNode,
      this.jobId,
      sourceNode.name,
      sourceNode.link
    );
    this.store.dispatch(uploadDesignFile({ file: filePath, node: targetNode }));

    const toPath = this.extractToPath(filePath.path);
    this.fileService.map(sourceNode.link, toPath);
  }

  extractToPath(toPath: string) {
    const folderParts = toPath.split('/');
    folderParts.pop();

    return folderParts.join('/') + '/';
  }

  onAddFolderClick(node: TreeNode) {
    this.showAddFolderForm = true;
  }

  removeNode(nodeToRemove: TreeNode) {
    this.store.dispatch(removeTargetElement({ node: nodeToRemove }));
  }
  onSubmitNewFolder(val: string) {
    this.store.dispatch(createFolder({ name: val }));
  }

  onFileSelected(event: any, node: TreeNode) {
    const file = event.target.files[0];
    const fileName = file.name;
    const filePath = extractPathFromNode(node, this.jobId, fileName);
    this.store.dispatch(uploadDesignFile({ file: filePath, node }));
    this.fileService.upload(file, filePath.path);
  }

  editProperties(node: TreeNode) {
    console.log(node);
    this.dialog.open(DesignPropertiesComponent, {
      backdropClass: 'custom-dialog',
      disableClose: true,
      data: {
        jobCode: this.jobId,
        selectedNode: node,
        formType: 'section',
        designData:this.designData,
      },
      width: '600px',
    });
  }
}
