<ul class="tab-nav">
  @for (tab of tabs; track tab) {
    <li>
      <button
        class="btn rounded-0 border-0"
        (click)="selectTab(tab)"
        [class.active]="tab.active"
        [disabled]="tab.disabled">
        {{ tab.title }}
      </button>
    </li>
  }
</ul>
<hr />
<ng-content></ng-content>
