import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class TbtStorageService {
  private readonly encryptionKey =
    'enc_Ewjhd648697wwuey65wuewqu75ghfsaasasreesrdrru7579ewe@uy213$jhd&jh%hgg%hgj&rypt!_135$7hgfffj90';

  constructor() {}

  setItem(key: string, value: string, shouldEncrypt = false): void {
    const finalValue = shouldEncrypt ? this.encrypt(value) : value;
    localStorage.setItem(key, finalValue);
  }

  getItem(key: string, shouldDecrypt = false): string | null {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return shouldDecrypt ? this.decrypt(storedValue) : storedValue;
    }
    return null;
  }

  private encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.encryptionKey).toString();
  }

  private decrypt(value: string): string {
    const bytes = CryptoJS.AES.decrypt(value, this.encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
