import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { SideBarComponent } from "./components/side-bar/side-bar.component";
import { EditorComponent } from "./components/editor/editor.component";
import { PropertiesComponent } from "./components/properties/properties.component";
import { TemplateLayers } from './models/template-layers';
import { CanvasEditorService } from './services/canvas-editor.service';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '@app/shared/services/template.service';
import { DesignsService } from '../dashboard/services/designs.service';
import { FooterComponent } from "../dashboard/components/footer/footer.component";
import { HeaderComponent } from "../dashboard/components/header/header.component";
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { SpinnerService } from '@app/shared/services/spinner.service';

@Component({
  selector: 'app-template-builder',
  standalone: true,
  imports: [NavBarComponent, SideBarComponent, EditorComponent, PropertiesComponent, FooterComponent, HeaderComponent, SpinnerComponent],
  templateUrl: './template-builder.component.html',
  styleUrl: './template-builder.component.scss'
})
export class TemplateBuilderComponent implements OnInit, OnDestroy {
  images: TemplateLayers[] = [];
  masterTemplateJson: any;
  templateId: string | null = null;
  templateData: any;
  jobCode: string = "";
  constructor(private canvasEditorService: CanvasEditorService, private templateService: TemplateService, private route: ActivatedRoute, private designsService: DesignsService, private spinnerService: SpinnerService) { }
  ngOnInit(): void {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    this.route.paramMap.subscribe(params => {
      this.templateId = params.get('templateId');
      this.jobCode = params.get('jobCode') || "";
      console.log('Received Job Code:', this.jobCode);

      if (this.templateId) {
        this.fetchTemplateData(this.templateId);
      }

      if (this.jobCode) {
        this.fetchDesignData(this.jobCode);
      }
    });
  }
  fetchTemplateData(templateId: string) {
    this.spinnerService.show();
    this.templateService.getTemplateById(templateId).subscribe(
      {
        next: (response) => {
          this.templateData = response;
          this.canvasEditorService.setTemplateConfig = this.templateData;
          this.spinnerService.hide();
          console.log('Fetched Template Data:', this.templateData);
        },
        error: (error) => {
          this.spinnerService.hide();
          console.error('Error occurred during subscription:', error); // Logs in case of an error.
        }
      }
    );
  }
  fetchDesignData(jobCode: string) {
    this.spinnerService.show();
    this.designsService.getDesign(jobCode).subscribe(
      {
        next: (designResponse) => {
          this.masterTemplateJson = designResponse;
          this.images = designResponse.static_files;
          this.updateFileType();
          this.spinnerService.hide();
        },
        error: (error) => {
          this.spinnerService.hide();
          console.error('Error fetching design data:', error);
        }
      }
    );
  }
  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }
  updateFileType() {
    this.images.map(element => {
      let tempElement = element.name.split('.').pop(),
        extension = tempElement?.toLowerCase() || "",
        imageArray = ["jpg", "jpeg", "png", "gif"];
      if (imageArray.indexOf(extension) > -1) {
        element.file_type = "image"
      } else { element.file_type = "text" }
    })
  }
}
