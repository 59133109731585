<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="standard-tree-design" [trackBy]="trackByFn">
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <app-tree-node [node]="node" [treeControl]="treeControl"></app-tree-node>
    <div [class.standard-tree-design-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>

  <mat-tree-node *matTreeNodeDef="let node"  [class]="!node.children ? 'no-child' : ''">
    <app-tree-node [node]="node" [treeControl]="treeControl"></app-tree-node>
  </mat-tree-node>
</mat-tree>