import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CanvasElement } from '../../models/canvas-element';
import { CanvasEditorService } from '../../services/canvas-editor.service';
import { FormsModule } from '@angular/forms';
import { MappingsComponent } from "../mappings/mappings.component";
import { TabItemComponent, TabsComponent } from '@app/shared/helpers';
import { ZAxisIcons } from '../../consts/z-axis-icons';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '@app/shared/services/template.service';

interface Customization {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-properties',
  standalone: true,
  imports: [FormsModule, TabsComponent, TabItemComponent, MappingsComponent],
  templateUrl: './properties.component.html',
  styleUrl: './properties.component.scss'
})
export class PropertiesComponent implements OnInit, OnDestroy {
  private readonly elementPropertiesSubscription!: Subscription;
  private readonly templateService = inject(TemplateService);
  private readonly route = inject(ActivatedRoute);

  currentElement: CanvasElement | null = null;
  public elements: CanvasElement[] = [];
  public editedText: string = '';
  public fontFamilies: string[] = [];
  customization: Customization[] = [
    { value: 'customization-id', viewValue: 'customization-id' },
    { value: 'med-legal-code', viewValue: 'med-legal-code' },
    { value: 'copyright', viewValue: 'copyright' }
  ];  
  selectedCustomization: string | undefined;
  jobCode=''; 
  @Input() templateData: any;

  constructor(private readonly canvasEditorService: CanvasEditorService) {

  }

  ngOnInit(): void {
    this.canvasEditorService.getElementSelectedObservable().subscribe(element => {
      this.currentElement = element;
      this.editedText = element?.text ?? '';
    });

    // this.loadFontsFromServer();

    this.route.paramMap.subscribe((params) => {
      this.jobCode = params.get('jobCode') ?? '';
  
      if (this.jobCode) {
        this.fetchfont(this.jobCode);
      }
    });
  }

  private fetchfont(jobCode: string): void {
    this.templateService.getDesigns(jobCode).subscribe((response: any) => {
      const urls = response['presigned-urls'];
      if (!urls) {
        return;
      }
  
      const fontUrls = Object.keys(urls).filter((key) =>
        key.includes(`${jobCode}/design/fonts`) 
      );
  
      fontUrls.forEach((fontUrl) => {
        const fontName = fontUrl.split('/').pop()?.split('.')[0];
        if (fontName) {
          const style = document.createElement('style');
          style.innerHTML = `
            @font-face {
              font-family: '${fontName}';
              src: url('${urls[fontUrl]}') format('woff2');
              font-weight: normal;
              font-style: normal;
            }
          `;
          document.head.appendChild(style);
          this.fontFamilies.push(fontName);
        }
      });
    });
  }

  loadFontsFromServer(): void {
    const fontUrls = [
      'https://fonts.googleapis.com/css?family=Cantarell:italic|Droid+Serif:bold',
      'https://fonts.googleapis.com/css?family=Cantarell:i|Droid+Serif:b',
      'https://fonts.googleapis.com/css?family=Cantarell:i|Droid+Serif:700'
    ];

    fontUrls.forEach(url => {
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = url;
      document.head.appendChild(linkElement);
    });
    this.fontFamilies = ['Cantarell', 'Droid Serif'];
  }

  get width(): number | null {
    return this.currentElement ? this.currentElement.width : null;
  }
  
  set width(value: number | null) {
    if (this.currentElement && value !== null && this.currentElement.type === 'text') {
      this.currentElement.width = value;
      this.updateElementDimensions();
    }
  }
  
  get height(): number | null {
    return this.currentElement ? this.currentElement.height : null;
  }
  
  set height(value: number | null) {
    if (this.currentElement && value !== null && this.currentElement.type === 'text') {
      this.currentElement.height = value;
      this.updateElementDimensions();
    }
  }
  
  get x(): number | null {
    return this.currentElement ? this.currentElement.x : null;
  }
  
  set x(value: number | null) {
    if (this.currentElement && value !== null) {
      this.currentElement.x = value;
      this.updateElementDimensions();
    }
  }
  
  get y(): number | null {
    return this.currentElement ? this.currentElement.y : null;
  }
  
  set y(value: number | null) {
    if (this.currentElement && value !== null) {
      this.currentElement.y = value;
      this.updateElementDimensions();
    }
  }
  
  get fontSize(): number | null | undefined{
    return this.currentElement ? this.currentElement.font_size : null;
  }

  set fontSize(value: number | null) {
    if (this.currentElement && value !== null) {
      this.currentElement.font_size = value;
      this.updateElementDimensions();
    }
  }

  get fontFamily(): string | null | undefined {
    return this.currentElement ? this.currentElement.font_family : null;
  }

  set fontFamily(value: string | null) {
    if (this.currentElement && value !== null) {
      this.currentElement.font_family = value;
      this.updateElementDimensions();
    }
  }

  toggleStaticElement(): void {
    if (this.currentElement) {
      this.currentElement.customizable = !this.currentElement.customizable;
      // this.store.dispatch(setZoneStatus({value: this.currentElement.customizable}));
      this.currentElement.is_dirty = true;
      this.currentElement.is_deleted = false;
      this.updateElementDimensions();
    }
  }

  ngOnDestroy(): void {
    if (this.elementPropertiesSubscription) {
      this.elementPropertiesSubscription.unsubscribe();
    }
  }

  // EditTextElement() {
  //   if (this.selectedCustomization && this.currentElement?.type === 'text') {
  //     this.currentElement.text = `{${this.selectedCustomization}}`;
  //     this.updateElementDimensions();
  //   }
  // }
  
  EditTextElement() {
    if (this.currentElement?.type === 'text') {
      this.currentElement.text = this.editedText;
      this.updateElementDimensions();
    }
  }

  updateElementDimensions(): void {
    if (this.currentElement) {
      this.canvasEditorService.updateElementDimensions(this.currentElement);
    }
  }
  removeBackgroundColor() {
    if (this.currentElement?.type === 'text') {
      this.currentElement.backgroundColor = null;
      this.updateElementDimensions();
    }
  }

  // changeElementOrder(stepUp: number) {
  //   if (this.currentElement) {
  //     // const currentElementIndex = this.elements.findIndex(element => element.id === this.currentElement?.id);
  //     // this.elements = this.elements.filter(element => element.id !== this.currentElement?.id);
  //     // this.elements.splice(currentElementIndex + stepUp, 0, this.currentElement)
  //     this.canvasEditorService.notifyElementMove(stepUp)
  //   }
  // }

  changeElementOrder(stepUp: number) {
    if (this.currentElement) {
      this.canvasEditorService.notifyElementMove(stepUp);
    }
  }

  btnIconType(iconType: string) {
    switch (iconType) {
      case ZAxisIcons.Up:
        return '&uarr;'; // Up arrow
      case ZAxisIcons.Down:
        return '&darr;'; // Down arrow
      case ZAxisIcons.UpTop:
        return '&uarr;&uarr;'; // Double up arrow
      case ZAxisIcons.DownTop:
        return '&darr;&darr;'; // Double down arrow
      default:
        return '';
    }
  }

  setTextAlign(alignment: 'left' | 'center' | 'right'): void {
    if (this.currentElement && this.currentElement.type === 'text') {
        this.currentElement.element_name = alignment;
        this.updateElementDimensions();
    }
  }


  moveLayer(direction: string): void {
    switch (direction) {
      case ZAxisIcons.Up:
        this.changeElementOrder(1);
        break;
      case ZAxisIcons.Down:
        this.changeElementOrder(-1);
        break;
      case ZAxisIcons.UpTop:
        this.changeElementOrder(2);
        break;
      case ZAxisIcons.DownTop:
        this.changeElementOrder(-2);
        break;
      default:
        break;
    }
  }
}
