<h2 mat-dialog-title>Properties</h2>
<mat-dialog-content>
  @if(formType === 'design' && propertiesForm) {
    <ng-container #designForm>
      <form [formGroup]="propertiesForm" class="image-form">
        <div>
          <h5>Image</h5>
          <mat-form-field>
            <mat-label>Design Name</mat-label>
            <input matInput formControlName="designName" type="text" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Design Type</mat-label>
            <mat-select formControlName="designType">
              <mat-option value="templated">Templated</mat-option>
              <mat-option value="picklist">Picklist</mat-option>
              <mat-option value="picklist_with_templated">Picklist with Templated</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Document Type</mat-label>
            <mat-select formControlName="documentType">
              <mat-option value="a4">A4</mat-option>
              <mat-option value="a3">A3</mat-option>
              <mat-option value="a5">A5</mat-option>
              <mat-option value="tabloid">Tabloid</mat-option>
              <mat-option value="letter">Letter</mat-option>
              <mat-option value="custom">Custom</mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <mat-label>Document Dimensions</mat-label>
            <div>
              <mat-form-field>
                <mat-label>Width</mat-label>
                <input matInput formControlName="documentWidth" type="number" required>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Height</mat-label>
                <input matInput formControlName="documentHeight" type="number" required>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field>
            <mat-label>Document Orientation</mat-label>
            <mat-select formControlName="documentOrientation">
              <mat-option value="portrait">Portrait</mat-option>
              <mat-option value="landscape">Landscape</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option value="English">English</mat-option>
              <mat-option value="Spanish">Spanish</mat-option>
              <mat-option value="Chinese">Chinese</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>No. of pages</mat-label>
            <input matInput formControlName="pageCount" type="number" required>
          </mat-form-field>
        </div>
      </form>
    </ng-container>
  } @else if (sectionForm) {
     <ng-container #sectionsForm>
       <form [formGroup]="sectionForm" class="image-form">

         <ng-container>
           <mat-form-field>
             <mat-label>File Type</mat-label>
             <mat-select formControlName="file_type">
               <mat-option value="image">Image</mat-option>
             </mat-select>
           </mat-form-field>
           <mat-checkbox formControlName="is_customizable">Is Customizable</mat-checkbox>

           <div formGroupName="imageGroup">
             <h6>Image</h6>
             <mat-form-field>
               <mat-label>X</mat-label>
               <input matInput formControlName="x" type="number" required>
             </mat-form-field>
             <mat-form-field>
               <mat-label>Y</mat-label>
               <input matInput formControlName="y" type="number" required>
             </mat-form-field>
             <mat-form-field>
               <mat-label>Width</mat-label>
               <input matInput formControlName="width" type="number" required>
             </mat-form-field>
             <mat-form-field>
               <mat-label>Height</mat-label>
               <input matInput formControlName="height" type="number" required>
             </mat-form-field>
           </div>

           @if(sectionForm.controls['is_customizable'].value) {

             <div formGroupName="zoneGroup">
               <h6>Zone</h6>
               <mat-form-field>
                 <mat-label>X</mat-label>
                 <input matInput formControlName="x" type="number" required>
               </mat-form-field>
               <mat-form-field>
                 <mat-label>Y</mat-label>
                 <input matInput formControlName="y" type="number" required>
               </mat-form-field>
               <mat-form-field>
                 <mat-label>Width</mat-label>
                 <input matInput formControlName="width" type="number" required>
               </mat-form-field>
               <mat-form-field>
                 <mat-label>Height</mat-label>
                 <input matInput formControlName="height" type="number" required>
               </mat-form-field>
             </div>
           }

         </ng-container>

       </form>
     </ng-container>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close class="button cancel-button" (click)="closeDialog()">Cancel</button>
  <button mat-button class="ok" (click)="updateProperties()">Done</button>
</mat-dialog-actions>


