<h2 mat-dialog-title class="dialog-title">{{ getTitle() }}
  <button
  mat-icon-button
  (click)="oncancelClick()"
  aria-label="Close"
  class="close-button">
  <mat-icon>close</mat-icon>
</button>
</h2>
<mat-dialog-content>
  @if (error) {
    <p class="error-message">{{ error }}</p>
  }
  <app-spinner></app-spinner>
    @if (data.type === 'submissionSuccess') {
      <p>
        <strong class="templateName">{{ data.resourceName }}</strong>
      </p>
    } @else {
      @if (data.type === 'confirmSubmit') {
        <p>
          <strong class="templateName">{{ data.resourceName }}</strong>
        </p>
        <mat-label class="label">{{ labels.confirmSubmit.label }}</mat-label>
        <mat-form-field appearance="fill" class="custom-textarea">
          <textarea
            matInput
            [(ngModel)]="comments"
            rows="1"
            maxlength="150"></textarea>
        </mat-form-field>
      }
      @if (data.type === 'approveSuccess') {
      }
      @if (data.type === 'confirmReject') {
        <mat-form-field appearance="fill">
          <mat-label>{{ labels.confirmReject.label }}</mat-label>
          <textarea matInput placeholder="{{ labels.confirmReject.placeholder }}" [(ngModel)]="comments" rows="5"></textarea>
        </mat-form-field>
      }
    }
</mat-dialog-content>

<mat-dialog-actions>
  @if (data.type === 'confirmSubmit') {
    <button mat-button class="confirm" (click)="onYesClick()" cdkFocusInitial>{{ yesText }}</button>
  }
  @if (data.type === 'confirmSubmit') {
    <button mat-button class="cancel" (click)="oncancelClick()">{{ cancelText }}</button>
  }
  @if (data.type === 'submissionSuccess') {
    <button mat-button class="ok" (click)="onOkClick()">{{ okText }}</button>
  }
  @if (data.type === 'approveSuccess') {
    <button mat-button class="ok" (click)="onOkClick()">{{ labels.approveSuccess.okText }}</button>
  }
  @if (data.type === 'confirmReject') {
    <button mat-button class="confirm" (click)="onYesClick()">{{ labels.confirmReject.rejectText }}</button>
  }
  @if (data.type === 'confirmReject') {
    <button mat-button class="cancel" (click)="oncancelClick()">{{ labels.confirmReject.cancelText }}</button>
  }
</mat-dialog-actions>
