import { Component ,Inject} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-dialog',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './status-dialog.component.html',
  styleUrl: './status-dialog.component.scss'
})
export class StatusDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<StatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string },private router: Router
  ) {}

  onClose(): void {
    this.dialogRef.close();
    if (this.data.title !== 'Design saved') {
      this.router.navigate(['/dashboard/designs'])
    }
  }
}