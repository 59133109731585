import { createReducer, on } from '@ngrx/store';
import { reset, setZoneStatus, updateDimension, updateHeight, updateWidth } from '../actions/properties.actions';

export const initialState: any = {
  dimension: {
    height: 0,
    width: 0
  },
  customizable: false
};

export const propertiesReducer = createReducer(
  initialState,
  on(updateHeight, (state, action) => {
    return { ...state, dimension: { ...state.dimension, height: action.height } }
  }),
  on(updateWidth, (state, action) => {
    return { ...state, dimension: { ...state.dimension, width: action.width } }
  }),
  on(setZoneStatus, (state, action) => {
    return { ...state, customizable: action.value }
  }),
  on(updateDimension, (state, action) => {
    return { ...state, dimension: { ...state.dimension, width: action.width, height: action.height } }
  }),
  on(reset, (state) => initialState)
);