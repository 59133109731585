export interface TreeNode {
  name: string;
  link?: string;
  view?: boolean;
  download?: boolean;
  upload?: boolean;
  children?: TreeNode[];
  addFolder?: boolean;
  type?: string;
  index?: number;
  draggable?: boolean;
  droppable?:boolean;
  parent?: string
}

export const TreeNodeTypes = {
  THUMBNAIL: "thumbnail",
  MAPPING_GUIDELINE_FILE: "mapping_guidelines_file",
  COMBINED_PDF: "combined_pdf",
  STATIC: "static",
  MODULE: "module",
  FONT: "font",
  LINKS: "links",
  INDD: "indd",
  IDML: "idml"
}

export interface UploadFile {
  name: string;
  path: string;
}

export interface FolderResponse {
  statusCode: number;
  body: {
    bucketname: string;
    contents: FolderContent[];
  };
}

export interface FolderContent {
  name: string;
  type: 'folder' | 'object';
  contents?: FolderContent[];
}

