import { createSelector } from '@ngrx/store';
import { AppState } from '../models';
import { FolderStructure } from '../models/folder-structure.model';

export const sourceTree = (state: AppState) => state.sourcetree;
export const targetTree = (state: AppState) => state.targettree;

export const selectSourceTree = createSelector(
    sourceTree,
    (folderStructure: FolderStructure) => folderStructure.treeData
);

export const selectTargetTree = createSelector(
    targetTree,
    (folderStructure: FolderStructure) => folderStructure.treeData
);

export const selectTargetDesignStructure = createSelector(
    targetTree,
    (folderStructure: FolderStructure) => folderStructure.data
);

export const selectJobId = createSelector(
    targetTree,
    (folderStructure: FolderStructure) => folderStructure.job
);