import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  TemplateDesign,
  TemplateServiceRequest,
  DesignMetadataResponse,
  endpoints,
} from '../constants/auth.constants';
import { environment } from '../../../environments/environment';
import { AuthService } from '@app/core/services/auth.service';
import { TbtStorageService } from './tbt-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DesignService {
  private readonly authService = inject(AuthService);
  private readonly http = inject(HttpClient);
  private readonly tbtStorageService = inject(TbtStorageService);

  private createHeaders(): HttpHeaders {
    const accessToken = this.tbtStorageService.getItem('access_token', true);
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);

    if (!accessToken || !instanceUrl) {
      throw new Error(
        'Access token or instance URL not found in local storage.'
      );
    }

    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });
  }

  getTemplateService(userId: string): Observable<TemplateDesign[]> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.designsEndPoint}`;
    const headers = this.createHeaders();

    const requestData: TemplateServiceRequest = {
      user_id: userId,
      method_code: '4',
      role: 'Author'
    };

    return this.http.post<TemplateDesign[]>(url, requestData, { headers });
  }

  getDesignMetadata(
    jobCode: string,
    userId: string,
    templateId: string,
    designName: string
  ): Observable<DesignMetadataResponse> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.designsEndPoint}`;
    const headers = this.createHeaders();
    const body = {
      job_code: jobCode,
      user_id: userId,
      template_id: templateId,
      design_name: designName,
    };

    return this.http.post<DesignMetadataResponse>(url, body, { headers });
  }

  saveFolderStructure(body: any) {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.designsEndPoint}`;
    const headers = this.createHeaders();
    
    return this.http.post<DesignMetadataResponse>(url, body, { headers });
  }

  previewfile(filePath: string): Observable<any> {
    const url = `${this.authService.getAWSUrl()}${endpoints.previewFile}?file_key=${filePath}`; 
    return this.http.get(url);
  }

}
