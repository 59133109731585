import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview-template',
  standalone: true,
  imports: [],
  templateUrl: './preview-template.component.html',
  styleUrl: './preview-template.component.scss'
})
export class PreviewTemplateComponent {
  @Input() imageSrc = '';
  show = false;

  open() {
    this.show = true;
  }
  onClose() {
    this.show = false;
  }

  zoomIn() {
    const myImg = document.getElementById("template-preview");
    if (myImg) {
      const currWidth = myImg.clientWidth;
      if (currWidth <= 2500) 
        myImg.style.width = (currWidth + 100) + "px";
    }
  }

  zoomOut() {
    const myImg = document.getElementById("template-preview");
    if (myImg) {
      const currWidth = myImg.clientWidth;
      if (currWidth >= 100)
        myImg.style.width = (currWidth - 100) + "px";
    }

  }
}
