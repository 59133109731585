<div class="container">
    <div class="head">
        <div class="title">{{ (dropDownConfig?.label ?? '') | sentenceCase }}</div>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Select an option</mat-label>
            <mat-select name="demographic" required [(ngModel)]="selectedValue" (selectionChange)="onSelectionChange()">
                @for (option of dropDownConfig?.options; track option.id) {
                <mat-option [value]="option">
                    {{ option.label }}
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
</div>