import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AwsSignerService {

  private credentials: any;
  private signer: any;
  private identityPoolId = 'ap-southeast-1:20e1865d-9777-4266-9192-1fea0897cd97'; // Your Identity Pool ID
  private region = 'ap-southeast-1'; // Your AWS region
  private userPoolDomain = 'cognito-idp.ap-southeast-1.amazonaws.com/ap-southeast-1_2w1u9tGe1'; // Your Cognito User Pool domain

  constructor() {}
}