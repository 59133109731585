import { createReducer, on } from '@ngrx/store';
import { createFolder, loadTargetTree, loadTargetTreeSuccess, removeTargetElement, uploadDesignFile } from '../../actions/standardise-design.actions';
import { FolderStructure } from '../../models/folder-structure.model';
import { buildTreeFromSF, removeNodeFromTree, updateTree, uuidv4 } from '@app/shared/helpers/tree-helpers';

export const initialState: FolderStructure = {
  treeData: [
    {
      name: 'Design',
      children: [
        { name: 'Thumbnail File' },
        { name: 'InDesign File' },
        { name: 'Mapping Guidelines file' },
        { name: 'Final Resource' },
        {
          name: 'Static Files',
          children: [],
        },
        {
          name: 'Module Assets',
          children: [],
          addFolder: true,
        },
      ],
    }
  ],
  job: "",
  data: {
    "design_name": "Untitled",
    "thumbnail": "",
    "mapping_guidelines_file": "",
    "combined_pdf": "",
    "template_type": "",
    "language": "",
    "status": "RAW",
    "design_file": [
      {
        "idml": "",
        "indd": "",
        "links": [],
        "fonts": []
      }
    ],
    "static_files": [],
    "Modules": []
  },
  loading: false
};

export const targetTreeReducer = createReducer(
  initialState,
  on(loadTargetTree, (state, {folder}) => {
    return { ...state, loading: true, job: folder }
  }),
  on(loadTargetTreeSuccess, (state, { payload }) => {
    return {
      ...state,
      loading: false,
      treeData: buildTreeFromSF(payload, state.job),
      data: payload
    };
  }),

  on(removeTargetElement, (state, {node}) => {
    let data = JSON.parse(JSON.stringify(state.data));
    data = removeNodeFromTree(data, node);
    return {
      ...state,
      data: data,
      treeData: buildTreeFromSF(data, state.job),
    }
  }),

  on(uploadDesignFile, (state, { file, node }) => {
    let data = JSON.parse(JSON.stringify(state.data));
    data = updateTree(file, node, data, state.job);
    return {
      ...state,
      data: data,
      treeData: buildTreeFromSF(data, state.job),
    };
  }),

  on(createFolder, (state, {name}) => {
    let data = JSON.parse(JSON.stringify(state.data));
    data.Modules.push({
      resource_type: "templated",
      name,
      id: uuidv4(),
      assets: [],
      index: data.Modules.length
    })
    return {
      ...state,
      data: data,
      treeData: buildTreeFromSF(data, state.job),
    };
  })
);