import { TreeNode, UploadFile } from '@app/features/dashboard/model/tree-node';
import { createAction, props } from '@ngrx/store';
// import { TreeNode, UploadFile } from 'src/app/components/dashboard/model/tree-node';

export const loadSourceTree = createAction('[Source Tree Load] Load the data', props<{ folder: any }>());
export const loadSourceTreeSuccess = createAction('[Source Tree Load] Load data completed', props<{ payload: any }>());
export const loadSourceTreeFailure = createAction('[Source Tree Load] Load data failed', props<{ payload: any }>());

export const loadTargetTree = createAction('[Target Tree Load] Load the data', props<{ folder: any }>());
export const loadTargetTreeSuccess = createAction('[Target Tree Load] Load data completed', props<{ payload: any }>());
export const loadTargetTreeFailure = createAction('[Target Tree Load] Load data failed', props<{ payload: any }>());

export const uploadDesignFile = createAction('[File upload] Upload the file', props<{ file: UploadFile, node: TreeNode }>());

export const removeTargetElement = createAction('[Remove Target element] Remove the file', props<{ node: TreeNode }>());

export const createFolder = createAction('[Create Folder] Create new folder', props<{ name: string }>());


