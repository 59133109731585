import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { endpoints } from '@app/shared/constants/auth.constants';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private readonly authService = inject(AuthService);
  private readonly http = inject(HttpClient);

  upload(file: any, path: string) {
    this.http.post(this.authService.getAWSUrl() + endpoints.uploadFiles, [path]).subscribe((response: any) => {
      let url = response.body[path];
      const formData = new FormData();

      formData.append('file', file, file.name);
      const upload$ = this.http.put(url, file, {headers: {"Content-Type": ""}});

      upload$.subscribe({
        next: () => {
          console.log("success")
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    })
  }

  map(fromPath: string, toPath: string) {
    this.http.post(`${this.authService.getAWSUrl()}${endpoints.copyFiles}`, {frompath: fromPath, topath: toPath, Isdelete: "no"}).subscribe({
      next: () => {
        console.log("success")
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  delete(path: string) {
    this.http.post(`${this.authService.getAWSUrl()}${endpoints.copyFiles}`, {topath: path, Isdelete: "yes"}).subscribe({
      next: () => {
        console.log("success")
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }
}
