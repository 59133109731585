<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">{{ data.title }}</span>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <img [src]="data.imageUrl" class="img-fluid" alt="Image Preview">
      </div>
    </div>
  </div>
  