import { INavButtons } from '../models';

export const navButtons: INavButtons[] = [
  {
    id: 0,
    label: 'Templates',
    icon: '',
    route: 'templates',
  },
  {
    id: 1,
    label: 'Designs',
    icon: '',
    route: 'designs',
  },
];
// {
//   id: 0,
//   label: 'Standardize Designs',
//   icon: '',
//   route: 'standardize-designs'
// },
