export interface ISFAuth {
  access_token: string;
  instance_url: string;
  id: string;
  token_type: string;
  issued_at: string;
  signature: string;
}

export interface IUserRole {
  totalSize: number;
  done: boolean;
  records: IUserRecord[];
}
interface IUserRecord {
  attributes: Attributes;
  MSD_CORE_User_Name__c: string;
  MSD_CORE_User_Role__c: string;
  MSD_CORE_HEQ_Approver_Id__c: string;
}
interface Attributes {
  type: string;
}

export enum EUserRole {
  author = 'Author',
  approver = 'Approver',
  customer = 'CU',
  account_executive = 'AE',
}
